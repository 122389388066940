import { getUserProfile, getUser, clearCurrentSession } from "@/utils/api";

export default {
  SET_USER({ commit }, payload) {
    getUser;
    return new Promise((resolve) => {
      const entityCode = payload.code;
      getUser(entityCode).then((resp) => {
        const userId = resp.data.id;
        commit("ADVISOR", []);
        commit("CASH_HOLDING", []);
        commit("HOLDINGS", []);
        commit("CURRENCY_RATES", []);
        commit("DOCUMENTS", []);
        commit("HOLDING_PERIOD", []);
        commit("INVESTMENTS", []);
        commit("PORTFOLIOS", []);
        commit("PORTFOLIO_VALUATION", []);
        commit("PROMOTIONS", []);
        commit("SET_USER", userId);
        commit("SET_USER_SEARCH", entityCode);
        resolve(resp);
      });
    });
  },

  USER_PROFILE({ commit }) {
    return new Promise((resolve, reject) => {
      getUserProfile()
        .then((resp) => {
          commit("HAS_PORTFOLIO", true);
          commit("USER_PROFILE", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) clearCurrentSession();
          reject(err);
        });
    });
  },

  SET_CURRENCY({ commit }, payload) {
    localStorage.setItem("user-currency", payload);
    commit("SET_CURRENCY", payload);
  },

  SET_POLICY({ commit }, payload) {
    localStorage.setItem("user-policy", payload);
    commit("SET_POLICY", payload);
  },

  HAS_PORTFOLIO({ commit }, payload) {
    commit("HAS_PORTFOLIO", payload);
  },
};
