import { getPromotions, clearCurrentSession } from "@/utils/api";

export default {
  PROMOTIONS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.promotions && state.promotions.length > 0) {
        resolve(state.promotions);
      } else {
        getPromotions()
          .then((resp) => {
            commit("PROMOTIONS", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
