import moment from "moment";
import { compareTime, dateTimeFormat } from "@/utils/date_helpers.js";

export default {
  isAuthenticated: (state) => {
    if (state.tokenExpiry) {
      const currentDateTime = moment().utc().format(dateTimeFormat);
      if (compareTime(currentDateTime, state.tokenExpiry)) {
        return !!state.user_token;
      }
    }
    return false;
  },
  user_token: (state) => state.user_token,
  authStatus: (state) => state.status,
  employeeSession: (state) => state.employeeSession,
};
