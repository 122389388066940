<template>
  <div>
    <img
      class="login-branding--image"
      id="loginBrandingImage"
      src="@/assets/images/carrick_logo.png"
      alt="Carrick. | Concierge"
    />
    <h4>
      Unfortunately, the Carrick. | Concierge - Portfolio is currently not
      receiving data from your providers. We are working closely with your
      providers to have this rectified as soon as possible so that we can
      display your complete portfolio.
    </h4>
    <h4>Please contact your Carrick representative for more information.</h4>
  </div>
</template>

<script>
export default {
  name: "MissingProfile",
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/components/missingprofile/missingprofile";
</style>
