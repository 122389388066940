export default {
  SET_CURRENCY_SELECTOR(state, resp) {
    localStorage.setItem("currency-selector-state", resp);
    state.currencySelectorState = resp;
  },

  SET_POLICY_SELECTOR(state, resp) {
    localStorage.setItem("policy-selector-state", resp);
    state.policySelectorState = resp;
  },

  SET_PRODUCTS_GAIN_LOSS(state, resp) {
    localStorage.setItem("products-gain-loss-state", resp);
    state.productsGainLossState = resp;
  },
};
