<template>
  <section id="productsPage">
    <PageTitle :text="'Product Performance'" />

    <ProductsPerformance :width="'80%'" />
  </section>
</template>

<script>
import PageTitle from "@/components/general/PageTitle";
import ProductsPerformance from "@/components/products/ProductsPerformance";

export default {
  name: "Portfolio",

  components: {
    PageTitle,
    ProductsPerformance,
  },
};
</script>

<style lang="scss" scoped></style>
