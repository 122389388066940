import moment from "moment";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dateTimeFormat = "YYYY-MM-DD LTS";

export const addTime = (dateTime, value, timeType) => {
  return moment(dateTime, dateTimeFormat)
    .add(value, timeType)
    .format(dateTimeFormat);
};

export const compareTime = (dateStringOne, dateStringTwo) => {
  const dateOne = moment(dateStringOne, dateTimeFormat);
  const dateTwo = moment(dateStringTwo, dateTimeFormat);
  if (dateOne.isBefore(dateTwo.toDate())) {
    return true;
  }
  return false;
};
export const parseDateString = (dateString, format = dateTimeFormat) => {
  return moment(dateString, format);
};
