<template>
  <Note :text="lastUpdatedString" />
</template>

<script>
import _ from "lodash";
import Note from "@/components/general/Note";

export default {
  name: "LastUpdated",

  props: {
    model: {
      type: String,
      required: true,
    },
  },

  components: {
    Note,
  },

  computed: {
    lastUpdatedString: function () {
      return _.find(this.$store.getters.lastUpdatedObjects, [
        "model",
        this.model,
      ]).last_updated;
    },
  },
};
</script>

<style lang="scss"></style>
