<template>
  <div v-if="show">
    <div class="modal-background--blur"></div>
    <div class="modal--container" id="modalContainer">
      <div class="modal-inner--container" id="modalInnerContainer">
        <div class="modal-content--container" id="modalContentContainer">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["show"],
};
</script>

<style lang="scss" scoped>
//
@import "@/assets/styles/settings";
@import "@/assets/styles/components/modal/modal";
</style>
