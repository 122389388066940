<template>
  <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
// SCRIPTS
import _ from "lodash";
import { getColors } from "@/utils/color_generator";
import { cleanMoney } from "@/utils/string_helpers";

export default {
  name: "PortfolioProviderBreakdown",

  props: {
    width: {
      type: Number,
      default: window.innerWidth,
    },
    height: {
      type: Number,
      default: window.innerWidth,
    },
  },

  computed: {
    chartOptions() {
      const colors = getColors();
      return {
        title: {
          align: "center",
          text: "Portfolio Provider Breakdown",
          style: {
            color: "#881534",
          },
        },
        labels: Array.from(_(this.$store.getters.portfoliosByProvider).keys()),
        colors: colors,
        chart: {
          foreColor: "#0b253e",
          toolbar: { show: true },
        },
        stroke: {
          show: true,
          colors: colors,
          width: 2,
        },
        tooltip: {
          enabled: window.innerWidth > 600 ? true : false,
          y: {
            formatter: (value) =>
              cleanMoney(value, this.$store.getters.currency),
          },
        },
        legend: {
          position: "top",
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {},
              legend: {
                position: "top",
              },
            },
          },
        ],
      };
    },

    series() {
      const ser = this.$store.getters.portfoliosByProvider.map((obj) =>
        _(obj)
          .map(`${this.$store.getters.currency.toLowerCase()}_amount`)
          .map(parseFloat)
          .sum()
      );
      return Array.from(ser);
    },
  },

  methods: {
    cleanMoney,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
</style>
