<template>
  <nav class="mobile-navigation">
    <div class="--fixed">
      <div class="branding--container" v-on:click="onLinkClicked(links[0])">
        <img
          class="branding--image"
          src="@/assets/images/carrick_logo.svg"
          alt=""
        />
      </div>

      <div class="toggle-icon--container">
        <font-awesome-icon
          class="toggle--icon"
          icon="bars"
          v-on:click="() => toggleOpen(true)"
        />
      </div>
    </div>
  </nav>

  <div class="mobile-links--container" v-if="isOpen === true">
    <div class="links-branding--container">
      <img
        class="branding--image"
        src="@/assets/images/carrick_logo.svg"
        alt=""
      />
    </div>
    <div v-if="activeEntity !== undefined">{{ activeEntity }}</div>
    <div class="links--container">
      <div class="--container" v-for="link in links" :key="link">
        <div
          :class="[
            'link--container',
            active === `${link.name}` ? 'link-active' : '',
          ]"
          v-on:click="onLinkClicked(link)"
        >
          <font-awesome-icon class="link--icon" :icon="`${link.icon}`" />
          <p class="link--button">
            {{ link.niceName }}
          </p>
        </div>
      </div>
    </div>
    <div class="logout--container" v-on:click="onLogoutClicked">
      <h3>Logout</h3>
      <font-awesome-icon class="link--icon" icon="sign-out-alt" />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "activeEntity",
    "active",
    "links",
    "onLinkClicked",
    "onLogoutClicked",
    "toggleOpen",
    "isOpen",
  ],
};
</script>

<style lang="scss">
@import "@/assets/styles/components/navigation/mobile";
</style>
