<template>
  <div class="cc--container" id="ccContainer">
    <DatePicker
      :buttonText="'Set Date'"
      :showDay="false"
      v-on:setDate="setDate"
    />
  </div>
  <WidgetLoader v-if="loading" />
  <div v-else>
    <h4>
      {{ startDateString }} - 8
      {{ date.month }}
      {{ date.year }}
    </h4>

    <AccountSummary :load="dataReady" :date="`${date.month} ${date.year}`" />
    <HoldingDataPeriod :load="dataReady" />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/datepicker/DatePicker";
import AccountSummary from "./AccountSummary";
import HoldingDataPeriod from "./HoldingDataPeriod";
import WidgetLoader from "@/components/loaders/WidgetLoader";

export default {
  name: "HoldingPeriodGroup",

  components: {
    AccountSummary,
    HoldingDataPeriod,
    DatePicker,
    WidgetLoader,
  },

  data() {
    return {
      loading: true,
      dataReady: false,
      date: new Date(),
    };
  },

  mounted() {
    const today = new Date().getDate();

    if (today >= 9) {
      this.date = {
        year: new Date().getFullYear(),
        month: moment().month(moment().month()).format("MMMM"),
      };
      this.setDate(this.date);
    } else {
      this.date = {
        year: new Date().getFullYear(),
        month: moment()
          .month(moment().month())
          .subtract(1, "months")
          .format("MMMM"),
      };
      this.setDate(this.date);
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === "SET_CURRENCY" || mutation.type === "SET_POLICY") {
        this.dataReady = false;
        this.setDate(this.date);
      }
    });
  },

  computed: {
    startDate() {
      const currentDate = new Date(`08 ${this.date.month} ${this.date.year}`);
      return moment(currentDate).subtract(1, "months");
    },
    startDateString() {
      return this.startDate.format("8 MMMM YYYY");
    },
  },

  methods: {
    setDate(date) {
      this.loading = true;

      if (date && date.year && date.month) {
        this.date = date;
        const rawDate = new Date(`01 ${date.month} ${date.year}`);
        this.$store
          .dispatch("HOLDING_PERIOD", {
            startDate: {
              year: rawDate.getFullYear(),
              month: rawDate.getMonth() + 1,
            },
          })
          .then(() => {
            this.loading = false;
            this.dataReady = true;
          });
      }
    },
  },
};
</script>
