<template>
  <div class="information--container" id="informationContainer">
    <div class="information-main--image" v-if="image">
      <ImageLoader v-if="imageLoading" />
      <div class="-image--container">
        <img
          style="
             {
              display: hidden;
            }
          "
          :src="image"
          :onerror="placeHolderImage"
          loading="eager"
          :onload="hideLoader"
        />
      </div>
    </div>

    <div
      class="information-content--container"
      :class="{ shadow: showShadow }"
      :style="{ width: width, height: height }"
    >
      <Group v-for="group in data" :key="group" :data="group" />

      <button
        id="sendMessageButton"
        title="Send message to advisor"
        class="information--button"
        v-on:click="() => $emit('icon-click')"
      >
        <font-awesome-icon v-if="icon !== ''" :icon="icon" />
      </button>
    </div>
  </div>
</template>

<script>
import ImageLoader from "@/components/loaders/ImageLoader";
import Group from "./_Group";

export default {
  name: "InformationCard",

  components: { ImageLoader, Group },

  props: {
    width: { type: String, default: "400px" },
    height: { type: String, default: "auto" },
    data: { type: Array },
    icon: { type: String, default: "" },
    image: { type: String, default: "" },
    showShadow: { type: Boolean, default: true },
  },

  data() {
    return {
      imageLoading: true,
    };
  },

  methods: {
    placeHolderImage(event) {
      event.target.setAttribute("class", "placeholder--image");
      this.imageLoading = true;
      event.target.onerror = null;
      event.target.src = `${process.env.VUE_APP_S3_STORAGE_URL}/employees/profile_picture_placeholder.svg`;
    },

    hideLoader() {
      this.imageLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/components/informationcard/informationcard";
</style>
