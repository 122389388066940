<template>
  <Modal :show="showModal" v-on:hide="hideModal">
    <h2>Please enter your message details</h2>
    <h2>to {{ advisor.first_name }} {{ advisor.last_name }}</h2>

    <div class="input--group">
      <label>Delivery Method</label>
      <div class="radio-bar">
        <input
          id="emailDelivery"
          type="radio"
          value="2"
          v-model="deliveryMethod"
        />
        <label for="emailDelivery">Email</label>
        <input
          id="smsDelivery"
          type="radio"
          value="1"
          v-model="deliveryMethod"
        />
        <label for="smsDelivery">SMS</label>
      </div>
    </div>

    <Input
      :label="'Subject'"
      :name="'subject'"
      :type="'text'"
      :data="this.subject"
      :placeholder="'Please enter the message subject'"
      v-on:onValueChanged="
        (resp) => {
          this.subject = resp;
        }
      "
    />

    <div class="input--group">
      <label for="message">Message</label>
      <div class="input-single--container">
        <textarea
          name="message"
          class="custom--input"
          id="messageTextArea"
          cols="30"
          rows="10"
          v-model="message"
          :placeholder="'Please enter the message'"
          required
        />
      </div>
    </div>

    <button id="sendMessageButton" v-on:click="sendMessage">Submit</button>
    <button
      class="secondary--button"
      id="hideModalButton"
      v-on:click="hideModal"
    >
      Close
    </button>
  </Modal>
</template>

<script>
import Input from "@/components/input/Input";
import Modal from "@/components/modal/Modal";
import { sendMessage, clearCurrentSession } from "@/utils/api";

export default {
  name: "AdvisorContactForm",

  props: {
    advisor: Object,
    showModal: Boolean,
  },

  components: {
    Input,
    Modal,
  },

  data() {
    return {
      deliveryMethod: "2",
      subject: "",
      message: "",
    };
  },

  methods: {
    sendMessage() {
      const payload = {
        employee_pk: this.advisor.id,
        message_subject: this.subject,
        message: this.message,
        desired_delivery_method: this.deliveryMethod,
      };

      sendMessage(payload)
        .then(() => {
          this.$wkToast("Message sent", {
            className: "wk-info",
          });
          this.message = "";
          this.subject = "";
          this.deliveryMethod = "2";
          this.hideModal();
        })
        .catch((err) => {
          if (err.response.status === 401) clearCurrentSession();
          this.$wkToast(err, {
            className: "wk-alert",
          });
        });
    },

    hideModal() {
      this.$emit("hide-modal");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base.scss";
@import "@/assets/styles/settings.scss";
@import "@/assets/styles/components/input/input.scss";
</style>
