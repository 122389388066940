<template>
  <WidgetLoader v-if="loading" />
  <div v-if="loading === false">
    <vueper-slides autoplay :bullets="true" fixed-height="9.5vw">
      <vueper-slide
        v-for="slide in $store.getters.promotions"
        :key="slide"
        :slide="slide"
        :image="slide.background"
        :link="slide.link"
      />
      <template v-slot:arrow-left>
        <font-awesome-icon class="control--icon" icon="chevron-left" />
      </template>

      <template v-slot:arrow-right>
        <font-awesome-icon class="control--icon" icon="chevron-right" />
      </template>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

import WidgetLoader from "@/components/loaders/WidgetLoader";

export default {
  name: "PromotionsComponent",

  components: {
    WidgetLoader,
    VueperSlides,
    VueperSlide,
  },

  mounted() {
    this.$store.dispatch("PROMOTIONS", {}).then(() => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/promotions/promotions";
</style>
