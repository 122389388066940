import { getInvestments, getPortfolio, clearCurrentSession } from "@/utils/api";

export default {
  INVESTMENTS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.investments && state.investments.length > 0) {
        resolve(state.investments);
      } else {
        if (state.user_pk) {
          getInvestments()
            .then((resp) => {
              commit("INVESTMENTS", resp.data);
              resolve(resp.data);
            })
            .catch((err) => {
              if (err.response.status === 401) clearCurrentSession();
              reject(err);
            });
        }
      }
    });
  },

  PORTFOLIOS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.portfolios && state.portfolios.length > 0) {
        resolve(state.portfolios);
      } else {
        if (state.user_pk) {
          getPortfolio()
            .then((resp) => {
              commit("PORTFOLIOS", resp.data);
              resolve(resp.data);
            })
            .catch((err) => {
              if (err.response.status === 401) clearCurrentSession();
              reject(err);
            });
        }
      }
    });
  },
};
