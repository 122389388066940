<template>
  <div>
    <div class="chart--container" :style="{ width: '70vw', margin: 'auto' }">
      <div v-if="total > 0">
        <apexchart :options="chartOptions" :series="series"> </apexchart>
      </div>
      <div v-else>No data to display</div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { cleanMoney } from "@/utils/string_helpers";
import { getColors } from "@/utils/color_generator";

export default {
  name: "PortfolioProductBreakdown",

  props: {
    width: {
      type: Number,
      default: window.innerWidth,
    },
    height: {
      type: Number,
      default: window.innerHeight,
    },
  },

  computed: {
    chartOptions() {
      return {
        colors: this.getColors(),
        labels: this.labels,
        legend: {
          show: true,
          fontSize: "13px",
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
        },
        chart: {
          type: "donut",
          foreColor: "#881534",
          toolbar: { show: true },
        },
        title: {
          text: "Portfolio Product Breakdown",
          align: "center",
          style: {
            color: "#881534",
          },
        },
        tooltip: {
          y: {
            formatter: (value) =>
              cleanMoney(value, this.$store.getters.currency),
            title: {
              formatter: (value) => {
                if (_.includes(value, ",")) {
                  // Rejoin the product name and remove the percentage.
                  return _(value).split(",").dropRight(1).join(" ");
                }
                return value;
              },
            },
          },
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  formatter: (w) => {
                    return cleanMoney(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0),
                      this.$store.getters.currency
                    );
                  },
                },
                value: {
                  formatter: (value) =>
                    cleanMoney(parseFloat(value), this.$store.getters.currency),
                },
              },
            },
          },
        },

        states: {
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
      };
    },

    total() {
      const portfolios = this.$store.getters.sortedPortfolios;

      return _(portfolios)
        .map(`${this.$store.getters.currency.toLowerCase()}_amount`)
        .map(parseFloat)
        .sum();
    },

    labels() {
      const portfolios = this.$store.getters.sortedPortfolios;

      const total = this.total;

      const labels = [];

      portfolios.forEach((element) => {
        labels.push(this.generateLabel(element, total));
      });

      return labels;
    },

    series() {
      const portfolios = this.$store.getters.sortedPortfolios;
      const total = this.total;
      const data = [];
      const labels = [];

      let amount = 0.0;

      portfolios.forEach((element) => {
        const value = parseFloat(
          element[`${this.$store.getters.currency.toLowerCase()}_amount`]
        );
        labels.push(this.generateLabel(element, total));
        amount = value + amount;
        data.push(value);
      });
      return data;
    },
  },

  methods: {
    cleanMoney,

    getColors,

    clear() {
      this.$store.commit("PORTFOLIOS", { data: [] });
    },

    generateLabel(element, total) {
      const nameArray = this.wordWrap(element.product_description, 30);
      const percentage =
        100 *
        (element[`${this.$store.getters.currency.toLowerCase()}_amount`] /
          total);
      nameArray.push(`${percentage.toFixed(2)}%`);
      return nameArray;
    },

    wordWrap(str, maxWidth) {
      var res = [];
      while (str.length > maxWidth) {
        var found = false;
        // Inserts new line at first whitespace of the line
        for (var i = maxWidth - 1; i >= 0; i--) {
          if (this.testWhite(str.charAt(i))) {
            res.push(str.slice(0, i));
            str = str.slice(i + 1);
            found = true;
            break;
          }
        }
        if (!found) {
          res.push(str.slice(0, maxWidth));
          str = str.slice(maxWidth);
        }
      }
      res.push(str);
      return res;
    },

    testWhite(x) {
      var white = new RegExp(/^\s$/);
      return white.test(x.charAt(0));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/base";
.vue-apexcharts {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
</style>
