<template>
  <section id="portfolioPage">
    <PageLoader v-if="loading" />
    <div v-else>
      <PageTitle :text="'Portfolio'" />
      <WIP/>
    </div>
  </section>
</template>

<script>
import PageTitle from "@/components/general/PageTitle";
import WIP from "@/components/wip/WIP";

export default {
  name: "Portfolio",

  components: {
    PageTitle,
    WIP,
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    const valuationsPromise = this.$store.dispatch("PORTFOLIO_VALUATION", {});
    const currencyRatesPromise = this.$store.dispatch("CURRENCY_RATES", {});
    const lastUpdatedPromise = this.$store.dispatch("LAST_UPDATED", {});
    Promise.all([
      valuationsPromise,
      currencyRatesPromise,
      lastUpdatedPromise,
    ]).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss"></style>
