import { shuffle } from "lodash";

export const generateRGBA = (length, alpha = 1) => {
  let min = 50;
  let max = 250;

  let colors = [];
  for (let i = 0; i < length; i++) {
    let red = Math.floor(Math.random() * (+max - +min)) + +min;
    let green = Math.floor(Math.random() * (+max - +min)) + +min;
    let blue = Math.floor(Math.random() * (+max - +min)) + +min;
    colors.push("rgba(" + red + "," + green + "," + blue + ", " + alpha + ")");
  }
  return colors;
};

export const getColors = () => {
  let coreColors = ["#881534", "#0b253e", "#bf975c"];
  let colors = [
    "#464646",
    "#959595",
    "#c2c2c2",
    "#5C84BF",
    "#158869",
    "#BF5CB5",
  ];
  return [...coreColors, ...shuffle(colors)];
};
