import { getLastUpdated, clearCurrentSession } from "@/utils/api";

export default {
  LAST_UPDATED({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.lastUpdatedObjects && state.lastUpdatedObjects.length > 0) {
        commit("LAST_UPDATED", state.lastUpdatedObjects);
        resolve();
      } else {
        getLastUpdated()
          .then((resp) => {
            commit("LAST_UPDATED", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
