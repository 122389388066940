import { getCashHoldings, clearCurrentSession } from "@/utils/api";

export default {
  CASH_HOLDING({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.holdings && state.holdings.length > 0) {
        commit("CASH_HOLDING", state.holdings);
        resolve();
      } else {
        getCashHoldings()
          .then((resp) => {
            commit("CASH_HOLDING", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },

  HOLDINGS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.holdings && state.holdings.length > 0) {
        resolve(state.holdings);
      } else {
        getCashHoldings()
          .then((resp) => {
            commit("HOLDINGS", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
