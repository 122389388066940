import amplitude from "amplitude-js";
import { getUserData } from "@/utils/utilities.js";
const apiKey = process.env.VUE_APP_AMPLITUDE_KEY;

export const logAnalyticData = async (eventName, eventData) => {
  const userData = getUserData();
  const { userId } = userData;
  delete userData.userId;

  amplitude.getInstance().init(apiKey);
  amplitude.getInstance().setUserId(userId);
  const deviceId = amplitude.options.deviceId;
  eventData["deviceId"] = deviceId;
  await amplitude.getInstance().logEvent(eventName, eventData);
};

export const navigationLinkClicked = (linkData) => {
  const { url, name } = linkData;
  logAnalyticData("navigation-clicked", { url, name });
};

export const floatingButtonClicked = (data) => {
  logAnalyticData("floating-selector-clicked", data);
};
