<template>
  <section id="404Page">
    <h1>Page not found</h1>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
