<template>
  <span :class="data.borderStyle" v-if="data.border === true" />

  <div
    class="group-title--container"
    id="groupTitelContainer"
    v-on:click="OnCollapseClicked"
  >
    <p :class="data.style">{{ data.title }}</p>

    <div class="group--options--container">
      <p
        class="group-edit--button"
        id="editButton"
        v-if="data.callback"
        v-on:click="data.callback()"
      >
        edit
      </p>

      <span v-else></span>
      <font-awesome-icon
        id="collapsibleButton"
        v-if="data.collapsible && collapseState"
        icon="chevron-up"
      />

      <font-awesome-icon
        id="collapsibleButton"
        v-if="data.collapsible && !collapseState"
        icon="chevron-down"
      />
    </div>
  </div>
  <span :class="data.borderStyle" v-if="data.border === true" />

  <div class="group-data--container">
    <div
      :class="[
        item.label ? 'card--item' : 'card-single--item',
        collapseState === true ? 'collapse--content' : '',
      ]"
      v-for="item in data.data"
      :key="item"
    >
      <p class="label" v-if="item.label && item.value">{{ item.label }}:</p>
      <p :class="item.style" v-if="item.value">
        <font-awesome-icon
          class="card--icon"
          v-if="item.icon && item.value"
          :icon="item.icon"
        />{{ item.value }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationCard",
  props: ["data"],

  data() {
    return {
      collapseState: false,
    };
  },

  methods: {
    OnCollapseClicked(e) {
      if (e.target.innerText != "edit" && this.data.collapsible)
        this.collapseState = !this.collapseState;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/components/informationcard/informationcard";
@import "@/assets/styles/components/informationcard/informationgroup";
</style>
