import { getAdvisor, clearCurrentSession } from "@/utils/api";

export default {
  ADVISOR({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.advisor && state.advisor.length > 0) {
        resolve(state.advisor);
      } else {
        getAdvisor()
          .then((resp) => {
            commit("ADVISOR", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
