<template>
  <apexchart
    type="radialBar"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: ["riskScore"],

  data() {
    return {
      series: [this.riskScore],
      chartOptions: {
        chart: {
          width: "100%",
          type: "radialBar",
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.1,
              },
            },
            track: {
              background: "#fff",
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 4,
                opacity: 0.2,
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#881534",
                fontSize: "20px",
              },
              value: {
                formatter: function (val) {
                  if (val === 0) return "-";
                  return `${parseInt(val)}%`;
                },
                color: "#111",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        colors: ["#0b253e"],
        stroke: {
          lineCap: "round",
        },
        labels: ["Risk Score"],
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
.vue-apexcharts {
  width: 100%;
  .apexcharts-canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
