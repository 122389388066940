<template>
  <section
    class="login-view--section"
    id="loginViewSection"
    :style="{
      'background-image':
        'url(' + require('@/assets/images/carrick_background.jpg') + ')',
    }"
  >
    <PageLoader v-if="loading" />

    <div class="cc-colum--container" id="ccColumnContainer" v-else>
      <div class="login--container" id="loginContainer">
        <div class="login-branding--container" id="loginBrandingContainer">
          <img
            class="login-branding--image"
            id="loginBrandingImage"
            src="@/assets/images/carrick_logo.png"
            alt=""
          />
        </div>

        <h2 id="userLoginText">{{ title }}</h2>

        <Input
          :label="'Username'"
          :name="'username'"
          :type="'username'"
          :disabled="otpGenerated"
          :data="username"
          :autofocus="true"
          :placeholder="'Enter your username'"
          v-on:onValueChanged="(resp) => (this.username = resp)"
          v-on:submit="onSubmit"
        />

        <div class="input--group" id="inputGroup" v-if="!otpGenerated">
          <label id="deliveryMethodLabel">Delivery Method</label>
          <div class="radio-bar" id="radioBarContainer">
            <input
              id="emailDeliveryInput"
              type="radio"
              value="2"
              v-model="deliveryMethodComputed"
            />

            <label id="emailDeliveryLabel" for="emailDeliveryInput"
              >Email</label
            >

            <input
              id="smsDeliveryInput"
              type="radio"
              value="1"
              v-model="deliveryMethodComputed"
            />
            <label id="smsDeliveryLabel" for="smsDeliveryInput">SMS</label>
          </div>
        </div>

        <Input
          :label="'OTP'"
          :name="'password'"
          :type="'password'"
          :placeholder="'Enter your OTP'"
          :autofocus="true"
          v-on:onValueChanged="(resp) => (this.password = resp)"
          v-if="otpGenerated"
          v-on:submit="onSubmit"
        />

        <div class="input--group" id="inputGroup">
          <button
            class="submit--button"
            id="submitButton"
            title="Submit login details"
            v-on:click="onSubmit"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateOTP, employeeGenerateOTP } from "@/utils/api";
import PageLoader from "@/components/loaders/PageLoader";
import Input from "@/components/input/Input";

export default {
  name: "Login",

  props: ["title", "entityType"],

  components: { PageLoader, Input },

  data() {
    return {
      loading: false,
      buttonText: "Next",
      otpGenerated: false,
      username: "",
      password: "",
      deliveryMethod: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.checkForToken();
    });
  },

  computed: {
    deliveryMethodComputed: {
      get() {
        if (this.deliveryMethod === "") {
          return this.entityType === "user" ? "1" : "2";
        }
        return this.deliveryMethod;
      },

      set(newValue) {
        this.deliveryMethod = newValue;
      },
    },
  },

  methods: {
    async checkForToken() {
      if (this.$router && this.$router.currentRoute) {
        let {
          token,
          entityCode,
          tokenCreated,
        } = this.$router.currentRoute.value.query;

        if ((!token, !tokenCreated)) return;

        await this.$store.dispatch("TOKEN_LOGIN", {
          token,
          tokenCreated,
          entityCode,
        });
        this.$router.push("/profile-search");
      }
    },

    onSubmit() {
      this.loading = true;
      const { username, password } = this;
      const deliveryMethod = this.deliveryMethodComputed;

      if (this.entityType === "user") {
        this.userLogin(username, password, deliveryMethod);
      } else if (this.entityType === "employee") {
        this.employeeLogin(username, password, deliveryMethod);
      }
    },

    userLogin(username, password, deliveryMethod) {
      if (this.otpGenerated) {
        this.$store
          .dispatch("AUTH_REQUEST", { username, password, deliveryMethod })
          .then(() => {
            this.$router.go("/");
          });
      } else {
        this.otpResponse(generateOTP(username, deliveryMethod));
      }
    },

    employeeLogin(username, password, deliveryMethod) {
      if (this.otpGenerated) {
        this.$store
          .dispatch("EMPLOYEE_AUTH_REQUEST", {
            username,
            password,
            deliveryMethod,
          })
          .then(() => {
            this.$router.push("/profile-search");
          });
      } else {
        this.otpResponse(employeeGenerateOTP(username, deliveryMethod));
      }
    },

    otpResponse(response) {
      response
        .then(() => {
          this.otpGenerated = true;
          this.buttonText = "Login";
        })
        .catch(() => {
          try {
            this.$wkToast("Error generating OTP", { className: "wk-alert" });
          } catch (error) {
            console.warn("$wkToast not instantiated");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/login.scss";
</style>
