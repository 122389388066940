<template>
  <div class="loader-page-loader--container" id="pageLoaderContainer">
    <Loader size="100px" :color="'#881534'" />
  </div>
</template>

<script>
import Loader from "vue-spinner/src/DotLoader.vue";
export default {
  name: "PageLoader",
  components: { Loader },
};
</script>

<style lang="scss" scoped>
.loader-page-loader--container {
  position: relative;
  margin: 0 auto;
  width: 50vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
