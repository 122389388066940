export default {
  currencySelectorState: (state) => {
    const show = state.currencySelectorState;
    if (show === "true" || show === true) return true;
    return false;
  },

  policySelectorState: (state) => {
    const show = state.policySelectorState;
    if (show === "true" || show === true) return true;
    return false;
  },

  productsGainLossState: (state) => state.productsGainLossState,
};
