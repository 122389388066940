<template>
  <div class="year-row-group" id="yearRowGroup">
    <font-awesome-icon
      class="link--icon clickable"
      id="getYearsPastButton"
      icon="chevron-left"
      v-on:click="getYears('past')"
    />
    <p class="title">{{ activeYear }}</p>
    <font-awesome-icon
      class="link--icon clickable"
      id="getYearsFutureButton"
      icon="chevron-right"
      v-on:click="getYears('future')"
    />
  </div>
  <div class="divider"></div>
  <div class="date-picker--items --year" id="datePickerItemsYear">
    <div
      v-for="year in years"
      :class="[
        'date-picker--container',
        activeYear === year ? 'active-item' : '',
      ]"
      id="datePickerContainer"
      v-bind:key="year"
      v-on:click="onYearClick(year)"
    >
      {{ year }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePickerYear",
  mounted() {
    this.getYears("past");
  },
  props: ["setView", "setYear", "year"],
  data() {
    return {
      startYear: this.year === null ? moment().year() : this.year,
      endYear: this.year === null ? moment().year() - 15 : this.year,
      activeYear: this.year === null ? moment().year() : this.year,
      years: [],
    };
  },
  methods: {
    getYears(direction) {
      const years = [];
      for (const i in [...Array(16).keys()]) {
        if (direction === "future") {
          years.push(moment().year(this.endYear).add(i, "years").year());
        }
        if (direction === "past") {
          years.push(moment().year(this.startYear).subtract(i, "years").year());
        }
      }
      if (direction === "past") years.reverse();
      this.startYear = years[0];
      this.endYear = years[years.length - 1];
      this.years = years;
    },
    onYearClick(year) {
      this.activeYear = year;
      this.setView("month");
      this.setYear(year);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/datepicker/datepicker";
</style>
