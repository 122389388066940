<template>
  <div
    :class="['policy-selector--container', expand === true ? 'expanded' : '']"
    :style="{ gridTemplateColumns: 'auto' }"
  >
    <ul
      :class="[
        'policy--list',
        this.expand ? 'show' : '',
        this.$store.getters.policies.length > 5 ? '--greater-than-five' : '',
      ]"
    >
      <li v-for="policy in this.$store.getters.policies" :key="policy">
        <p :id="`${policy}Button`" v-on:click="() => setPolicy(policy)">
          {{ $store.getters.policySelectorDisplay(policy) }}
        </p>
      </li>
    </ul>

    <button
      class="policy-selector--button"
      id="policySelectorButton"
      title="Show available policies"
      v-on:click="showPolicies"
    >
      Policy {{ this.$store.getters.policy }}
    </button>
  </div>
</template>

<script>
import { floatingButtonClicked } from "@/utils/analytics.js";
export default {
  name: "Policy Selector",
  data() {
    return { expand: false };
  },

  methods: {
    showPolicies() {
      this.expand = !this.expand;

      const routeName = this.$router.currentRoute.value.name;
      floatingButtonClicked({
        action: "toggled",
        name: "Policy Selector",
        routeName,
      });
    },

    setPolicy(policy) {
      this.$store.dispatch("SET_POLICY", policy);

      const routeName = this.$router.currentRoute.value.name;
      floatingButtonClicked({
        action: "clicked",
        name: "Policy Selector",
        policy,
        routeName,
      });

      this.expand = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/policyselector/policyselector";
</style>
