<template>
  <div class="component--container" id="componentContainer">
    <div
      class="table--container"
      id="accountSummaryTableContainer"
      v-if="load === true && products.length > 0"
    >
      <h3>Account Summary for {{ date }}</h3>
      <table>
        <!-- PORTFOLIO MOVEMENTS -->
        <tr>
          <th class="left-aligned-column">Portfolio Movements</th>
          <th></th>
          <th class="right-aligned-column">Amount</th>
        </tr>
        <tr>
          <td class="left-aligned-column">Opening market value</td>
          <td></td>
          <td class="right-aligned-column">
            {{ cleanMoney(openingMarketValue, $store.getters.currency) }}
          </td>
        </tr>
        <tr>
          <td class="left-aligned-column">Closing market value</td>
          <td></td>
          <td class="right-aligned-column">
            {{ cleanMoney(closingMarketValue, $store.getters.currency) }}
          </td>
        </tr>
        <tr>
          <td class="left-aligned-column">Net change in portfolio value</td>
          <td></td>
          <td class="right-aligned-column">
            {{ cleanMoney(changeInValue, $store.getters.currency) }}
          </td>
        </tr>
        <!-- PORTFOLIO MOVEMENTS END -->
        <!-- CASH BALANCE -->
        <tr v-show="showCashBalance">
          <th class="left-aligned-column">Cash Balance</th>
          <th></th>
          <th class="right-aligned-column">Amount</th>
        </tr>
        <tr v-show="showCashBalance">
          <td class="left-aligned-column">Opening Cash Balance</td>
          <td></td>
          <td class="right-aligned-column">
            {{ cleanMoney(openingCashBalance, $store.getters.currency) }}
          </td>
        </tr>
        <tr v-show="showCashBalance"></tr>
        <tr v-show="showCashBalance">
          <td class="left-aligned-column">Closing Cash Balance</td>
          <td></td>
          <td class="right-aligned-column">
            {{ cleanMoney(closingCashBalance, $store.getters.currency) }}
          </td>
        </tr>
        <!-- CASH BALANCE END -->
        <tr>
          <th class="left-aligned-column">Policy Number</th>
          <th class="left-aligned-column">Product Description</th>
          <th class="right-aligned-column">Weighting</th>
        </tr>
        <tr v-for="product in products" :key="product">
          <td class="left-aligned-column">{{ product.policy_number }}</td>
          <td class="left-aligned-column">{{ product.product_description }}</td>
          <td class="right-aligned-column">{{ product.weight }}%</td>
        </tr>
      </table>
    </div>
    <div v-else>
      <TitleText :text="`No Account data to show for ${date}`" />
    </div>
  </div>
</template>

<script>
// COMPONENTS
import TitleText from "@/components/general/TitleText.vue";
// SCRIPTS
import _ from "lodash";
import { cleanMoney } from "@/utils/string_helpers";
import { isCashProduct } from "../../utils/utilities";

export default {
  components: { TitleText },

  props: ["load", "date"],

  methods: {
    cleanMoney,

    getCashBalance(position) {
      const cash_balance = [];

      this.$store.getters.holdingPeriod.map((element) => {
        if (isCashProduct(element)) {
          cash_balance.push(
            parseFloat(
              element[
                `${position}_${this.$store.getters.currency.toLowerCase()}_amount`
              ]
            )
          );
        }
      });
      return _.sum(cash_balance);
    },
  },

  computed: {
    products: function () {
      const closingTotal = this.closingMarketValue;
      const currency = this.$store.getters.currency.toLowerCase();
      return this.$store.getters.holdingPeriodNoCash.map((element) => {
        const opening = parseFloat(element[`opening_${currency}_amount`]);

        const closing = parseFloat(element[`closing_${currency}_amount`]);

        const { policy_number, policy_name, product_description } = element;

        return {
          policy_number,
          policy_name,
          product_description,
          gainLoss: closing - opening,
          weight: parseFloat((closing / closingTotal) * 100).toFixed(2),
        };
      });
    },

    openingMarketValue: function () {
      const opening = `opening_${this.$store.getters.currency.toLowerCase()}_amount`;
      return _.sum(
        this.$store.getters.holdingPeriodNoCash.map((element) =>
          parseFloat(element[opening])
        )
      );
    },

    closingMarketValue: function () {
      const closing = `closing_${this.$store.getters.currency.toLowerCase()}_amount`;
      return _.sum(
        this.$store.getters.holdingPeriodNoCash.map((element) =>
          parseFloat(element[closing])
        )
      );
    },

    changeInValue: function () {
      return this.closingMarketValue - this.openingMarketValue;
    },

    openingCashBalance: function () {
      return this.getCashBalance("opening");
    },

    closingCashBalance: function () {
      return this.getCashBalance("closing");
    },

    showCashBalance: function () {
      return process.env.VUE_APP_SHOW_CASH_BALANCE === "true";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/settings.scss";
@import "@/assets/styles/base.scss";
</style>
