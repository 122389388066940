<template>
  <div class="tree--container">
    <TreeItem class="item" :item="treeData"></TreeItem>
  </div>
</template>

<script>
import TreeItem from "./TreeItem.vue";

export default {
  components: { TreeItem },

  props: ["treeData"],

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/documents/tree";
</style>
