import { getHoldingPeriodData, clearCurrentSession } from "@/utils/api";

export default {
  HOLDING_PERIOD({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getHoldingPeriodData(payload.startDate)
        .then((resp) => {
          commit("HOLDING_PERIOD", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) clearCurrentSession();
          reject(err);
        });
    });
  },
};
