<template>
  <WidgetLoader :text="'please wait while documents upload'" v-if="isLoading" />
  <div class="upload--container" v-else>
    <TitleText :text="'Document Upload'" />

    <div
      class="drop--container"
      id="dropContainer"
      @drop.prevent="addFile"
      @dragover.prevent
    >
      <font-awesome-icon
        class="upload--icon"
        icon="cloud-upload-alt"
        v-if="!isOpen"
      />

      <small class="information--text">
        drop and drop documents here to upload
      </small>
    </div>

    <button id="uploadButton" @click="upload" v-if="files.length > 0">
      Upload
    </button>
    <div class="files--container" v-if="files.length > 0">
      <ul class="dropped-files">
        <li v-for="file in files" :key="file.document.name">
          <WidgetLoader
            :text="'please wait while documents upload'"
            v-if="file.uploading"
          />

          <div class="file--container" v-else>
            <p class="file--name">{{ file.document.name }}</p>

            <div class="actions--container">
              <button
                :id="`${file.document.name}AddNotesButton`"
                @click="() => showUpdateModal(file)"
                title="Add Notes"
              >
                <font-awesome-icon icon="pen" />
              </button>

              <button
                class="remove--button"
                :id="`${file.document.name}RemoveItemButton`"
                @click="() => removeFile(file.document)"
                title="Remove Document"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <Modal :show="showModal" v-on:hide="hideModal">
    <h2>Please enter the notes for your document</h2>

    <p>
      {{ activeDocument.document.name }}
    </p>

    <textarea
      name="Document Notes"
      cols="30"
      rows="10"
      v-model="currentNotes"
    ></textarea>

    <button v-on:click="addNotes">Submit</button>

    <button class="secondary--button" v-on:click="hideModal">Close</button>
  </Modal>
</template>

<script>
import TitleText from "@/components/general/TitleText";
import Modal from "@/components/modal/Modal";
import WidgetLoader from "@/components/loaders/WidgetLoader";
import { uploadDocuments } from "@/utils/api";

export default {
  name: "Documents",

  components: {
    WidgetLoader,
    TitleText,
    Modal,
  },

  mounted() {
    this.$store.dispatch("DOCUMENTS");
  },

  data() {
    return {
      files: [],
      isLoading: false,
      showModal: false,
      activeDocument: null,
      currentNotes: "",
    };
  },

  methods: {
    showUpdateModal(file) {
      this.activeDocument = file;
      this.currentNotes = file.notes;
      this.showModal = true;
    },

    hideModal() {
      this.showModal = false;
    },

    addFile(event) {
      const droppedFiles = event.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((file) => {
        this.files.push({ document: file, notes: "", uploading: false });
      });
    },

    addNotes() {
      this.files.forEach((element) => {
        if (element.document === this.activeDocument.document) {
          element.notes = this.currentNotes;
          return;
        }
      });
      this.hideModal();
    },

    removeFile(file) {
      this.files = this.files.filter((element) => element.document != file);
    },

    async upload() {
      this.files.forEach((file) => (file.uploading = true));
      for (const file of this.files) {
        let formData = new FormData();
        formData.append("document", file.document);
        formData.append("notes", file.notes);
        await uploadDocuments(formData)
          .then(() => {
            file.uploading = false;
            this.removeFile(file.document);
            this.$wkToast("Document uploaded", { className: "wk-info" });
            this.$store.dispatch("DOCUMENTS");
          })
          .catch((error) => {
            file.uploading = false;
            this.$wkToast(
              `Failed to upload document ${file.document}\n${error}`,
              {
                className: "wk-alert",
              }
            );
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/components/uploadfile/uploadfile";
</style>
