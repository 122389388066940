import { months } from "@/utils/date_helpers.js";
import { isCashProduct } from "../../utils/utilities";

export default {
  holdingPeriod: (state) => {
    return state.holdingPeriod.filter((element) => {
      if (element.policy_number === state.policy || state.policy === "all") {
        return element;
      }
    });
  },

  holdingPeriodNoCash: (state, getters) => {
    return getters.holdingPeriod.filter((element) => !isCashProduct(element));
  },

  holdingPeriodOpeningDate: (state) => {
    if (state.holdingPeriod && state.holdingPeriod.length > 0) {
      let dateObj = new Date(state.holdingPeriod[0].opening_date);
      let dateString = `8 ${
        months[dateObj.getMonth()]
      } ${dateObj.getFullYear()}`;
      return dateString;
    }
    return "";
  },
};
