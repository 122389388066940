<template>
  <section id="documentsPage">
    <PageTitle :text="'My Documents'" />

    <Tree :treeData="treeData" />

    <UploadFile />
  </section>
</template>

<script>
// COMPONENTS
import PageTitle from "@/components/general/PageTitle";
import Tree from "@/components/documents/Tree";
import UploadFile from "@/components/documents/UploadFile";
// SCRIPTS
import _ from "lodash";
import { filterTree } from "@/utils/document_helpers";

export default {
  name: "Documents",

  components: {
    PageTitle,
    Tree,
    UploadFile,
  },

  computed: {
    treeData() {
      const docTree = _.cloneDeep(this.$store.getters.documents);
      const filteredTree = filterTree(docTree);
      return filteredTree[0];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/documents";
</style>
