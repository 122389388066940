export const providers = [
  {
    name: "CIG - Capital International Group",
    id: "4",
    imageUrl: "4_capital_international_group.png",
    active: true,
  },
  {
    name: "Liberty",
    id: "8",
    imageUrl: "6_liberty.png",
    active: true,
  },
  {
    name: "RL360",
    id: "8",
    imageUrl: "8_rl360.png",
    active: true,
  },
  {
    name: "Hansard International",
    id: "9",
    imageUrl: "9_hansard.png",
    active: true,
  },
  {
    name: "Friends Provident International",
    id: "19",
    imageUrl: "19_friends_provident_international.png",
    active: true,
  },
  {
    name: "Providence",
    id: "24",
    imageUrl: "24_providence.png",
    active: true,
  },
  {
    name: "Investec",
    id: "29",
    imageUrl: "29_investec.png",
    active: true,
  },
  {
    name: "Allan Gray",
    id: "32",
    imageUrl: "32_allan_gray.png",
    active: true,
  },

  {
    name: "Discovery Group",
    id: "33",
    imageUrl: "33_discovery.png",
    active: false,
  },
  {
    name: "Old Mutual International",
    id: "42",
    imageUrl: "42_old_mutual_international.png",
    active: true,
  },
  {
    name: "Cornhill Management",
    id: "148",
    imageUrl: "148_cornhill_management.png",
    active: true,
  },
  {
    name: "Utmost Wealth Solutions",
    id: "179",
    imageUrl: "179_utmost_international.png",
    active: true,
  },
  {
    name: "Praemium",
    id: "256",
    imageUrl: "256_praemium.png",
    active: true,
  },
  {
    name: "Ardan International",
    id: "320",
    imageUrl: "320_ardan_international.png",
    active: true,
  },
  {
    name: "Ninety one",
    id: "345",
    imageUrl: "345_ninety_one.png",
    active: true,
  },
  {
    name: "Old Mutual",
    id: "28",
    imageUrl: "28_old_mutual.png",
    active: false,
  },
  {
    name: "Brooks Macdonald",
    id: "13",
    imageUrl: "13_bm.png",
    active: true,
  },
  {
    name: "Momentum",
    id: "44",
    imageUrl: "44_momentum.png",
    active: true,
  },
  {
    name: "Stanlib",
    id: "54",
    imageUrl: "54_stanlib.png",
    active: true,
  },
  {
    name: "Glacier",
    id: "163",
    imageUrl: "163_glacier.png",
    active: false,
  },
  {
    name: "Wealthport",
    id: "437",
    imageUrl: "437_wealthport.png",
    active: true,
  },
  {
    name: "OutVest",
    id: "433",
    imageUrl: "433_outvest.png",
    active: false,
  }
];
