<template>
  <section>
    <div v-if="isPublic">
      <div class="preferences--container">
        <PageTitle :text="'User Preferences'" />

        <ToggleSwitch
          :id="currecySelector"
          :label="'Show Currency Selector'"
          :description="'Toggle the visibility of the Currency Selector.'"
          :initialState="currencySelectorState"
          v-on:toggle-changed="(resp) => setCurrency(resp)"
        />

        <ToggleSwitch
          :id="policySelector"
          :label="'Show Policy Selector'"
          :description="'Toggle the visibility of the Policy Selector.'"
          :initialState="policySelectorState"
          v-on:toggle-changed="(resp) => setPolicy(resp)"
        />

        <ToggleSwitch
          :id="productsGainLoss"
          :label="'Show Series Data (Products Gain/Loss)'"
          :description="'Toggle the default visibility of the series data for Products Gain/Loss percentage chart.'"
          :initialState="productsGainLossState"
          v-on:toggle-changed="(resp) => setProductsGainLoss(resp)"
        />
      </div>
    </div>
  </section>
</template>

<script>
// COMPONENTS
import PageTitle from "@/components/general/PageTitle";
import ToggleSwitch from "@/components/general/ToggleSwitch.vue";

export default {
  name: "User Preferences",

  components: { PageTitle, ToggleSwitch },

  computed: {
    isPublic() {
      return this.$store.getters.featurePreferences;
    },

    currencySelectorState() {
      return this.$store.getters.currencySelectorState;
    },

    policySelectorState() {
      return this.$store.getters.policySelectorState;
    },

    productsGainLossState() {
      return this.$store.getters.productsGainLossState;
    },
  },

  methods: {
    setCurrency(state) {
      this.$store.dispatch("SET_CURRENCY_SELECTOR_STATE", state);
    },

    setPolicy(state) {
      this.$store.dispatch("SET_POLICY_SELECTOR_STATE", state);
    },

    setProductsGainLoss(state) {
      this.$store.dispatch("SET_PRODUCTS_GAIN_LOSS_STATE", state);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
@import "@/assets/styles/views/preferences.scss";
</style>
