<template>
  <Login :title="'User Login'" :entityType="'user'" />
</template>

<script>
import Login from "@/components/login/Login";

export default {
  name: "Login View",
  components: { Login },
};
</script>

<style lang="scss" scoped></style>
