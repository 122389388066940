<template>
  <div class="loader-image-loader--container" id="widgetLoaderContainer">
    <Loader :color="'#881534'" />
  </div>
</template>

<script>
import Loader from "vue-spinner/src/RotateLoader.vue";
export default {
  name: "WidgetLoader",
  props: ["text"],
  components: { Loader },
};
</script>

<style lang="scss">
.loader-image-loader--container {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
