import { createStore, createLogger } from "vuex";

// ADVISORS
import advisorsS from "./states/advisors";
import advisorsA from "./actions/advisors";
import advisorsG from "./getters/advisors";
import advisorsM from "./mutations/advisors";
// AUTH
import authS from "./states/auth";
import authA from "./actions/auth";
import authG from "./getters/auth";
import authM from "./mutations/auth";
// CASH HOLDINGS
import cashHoldingsS from "./states/cash_holdings";
import cashHoldingsA from "./actions/cash_holdings";
import cashHoldingsG from "./getters/cash_holdings";
import cashHoldingsM from "./mutations/cash_holdings";
// CURRENCY RATES
import currencyRatesS from "./states/currency_rates";
import currencyRatesA from "./actions/currency_rates";
import currencyRatesG from "./getters/currency_rates";
import currencyRatesM from "./mutations/currency_rates";
// DOCUMENTS
import documentsS from "./states/documents";
import documentsA from "./actions/documents";
import documentsG from "./getters/documents";
import documentsM from "./mutations/documents";
// FEATURES
import featureS from "./states/feature";
import featureA from "./actions/feature";
import featureG from "./getters/feature";
import featureM from "./mutations/feature";
// HOLDING PERIOD
import holdingPeriodS from "./states/holding_period";
import holdingPeriodA from "./actions/holding_period";
import holdingPeriodG from "./getters/holding_period";
import holdingPeriodM from "./mutations/holding_period";
// INVESTMENTS
import investmentsS from "./states/investments";
import investmentsA from "./actions/investments";
import investmentsG from "./getters/investments";
import investmentsM from "./mutations/investments";
// LAST UPDATED
import lastUpdatedS from "./states/last_updated";
import lastUpdatedA from "./actions/last_updated";
import lastUpdatedG from "./getters/last_updated";
import lastUpdatedM from "./mutations/last_updated";
// NAVIGATION
import navigationS from "./states/navigation";
import navigationA from "./actions/navigation";
import navigationG from "./getters/navigation";
import navigationM from "./mutations/navigation";
// NOTIFICATIONS
import notificationsS from "./states/notifications";
import notificationsA from "./actions/notifications";
import notificationsG from "./getters/notifications";
import notificationsM from "./mutations/notifications";
// PORTFOLIO VALUATION
import portfolioValuationS from "./states/portfolio_valuation";
import portfolioValuationA from "./actions/portfolio_valuation";
import portfolioValuationG from "./getters/portfolio_valuation";
import portfolioValuationM from "./mutations/portfolio_valuation";
// PREFERENCES
import preferencesS from "./states/preferences";
import preferencesA from "./actions/preferences";
import preferencesG from "./getters/preferences";
import preferencesM from "./mutations/preferences";
// PROMOTIONS
import promotionsS from "./states/promotions";
import promotionsA from "./actions/promotions";
import promotionsG from "./getters/promotions";
import promotionsM from "./mutations/promotions";
// PROVIDERS
import providersS from "./states/providers";
import providersA from "./actions/providers";
import providersG from "./getters/providers";
import providersM from "./mutations/providers";
// USER
import userS from "./states/user";
import userA from "./actions/user";
import userG from "./getters/user";
import userM from "./mutations/user";

const getDefaultState = () => {
  return {
    ...advisorsS,
    ...authS,
    ...cashHoldingsS,
    ...currencyRatesS,
    ...documentsS,
    ...featureS,
    ...holdingPeriodS,
    ...investmentsS,
    ...lastUpdatedS,
    ...navigationS,
    ...notificationsS,
    ...portfolioValuationS,
    ...preferencesS,
    ...promotionsS,
    ...providersS,
    ...userS,
  };
};
const state = getDefaultState();

const actions = {
  ...advisorsA,
  ...authA,
  ...cashHoldingsA,
  ...currencyRatesA,
  ...documentsA,
  ...featureA,
  ...holdingPeriodA,
  ...investmentsA,
  ...lastUpdatedA,
  ...navigationA,
  ...notificationsA,
  ...portfolioValuationA,
  ...preferencesA,
  ...promotionsA,
  ...providersA,
  ...userA,
  RESET_USER({ commit }) {
    commit("RESET_USER");
  },
  CLEAR_ALL_DATA({ commit }) {
    commit("CLEAR_ALL_DATA");
  },
};

const getters = {
  ...advisorsG,
  ...authG,
  ...cashHoldingsG,
  ...currencyRatesG,
  ...documentsG,
  ...featureG,
  ...holdingPeriodG,
  ...investmentsG,
  ...lastUpdatedG,
  ...navigationG,
  ...notificationsG,
  ...portfolioValuationG,
  ...preferencesG,
  ...promotionsG,
  ...providersG,
  ...userG,
};

const mutations = {
  ...advisorsM,
  ...authM,
  ...cashHoldingsM,
  ...currencyRatesM,
  ...documentsM,
  ...featureM,
  ...holdingPeriodM,
  ...investmentsM,
  ...lastUpdatedM,
  ...navigationM,
  ...notificationsM,
  ...portfolioValuationM,
  ...preferencesM,
  ...promotionsM,
  ...providersM,
  ...userM,
  RESET_USER(state) {
    localStorage.removeItem("user-currency");
    localStorage.removeItem("user-policy");
    localStorage.removeItem("user-search");
    Object.assign(state, getDefaultState());
  },
  CLEAR_ALL_DATA(state) {
    Object.assign(state, getDefaultState());
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createLogger()],
});
