<template>
  <div class="row-group" id="rowGroup">
    <p class="title clickable" id="onDateClick" v-on:click="onDateClick">
      {{ activeMonth }} {{ year }}
    </p>
  </div>
  <div class="divider"></div>
  <div class="date-picker--items --month" id="datePickerItemsMonth">
    <div
      v-for="month in months"
      :class="[
        'date-picker--container',
        activeMonth === month ? 'active-item' : '',
      ]"
      id="datePickerContainer"
      v-bind:key="month"
      v-on:click="onMonthClick(month)"
    >
      {{ month }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePickerMonth",
  components: {},
  mounted() {},
  props: ["setView", "setMonth", "year", "month"],
  data() {
    return {
      activeMonth:
        this.month === null
          ? moment().month(moment().month()).format("MMMM")
          : this.month,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  methods: {
    onDateClick() {
      this.setView("year");
    },
    onMonthClick(month) {
      this.activeMonth = month;
      this.setView("day");
      this.setMonth(month);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/datepicker/datepicker";
</style>
