import axios from "axios";
import { wait } from "@/utils/auxiliary_helpers";
import store from "@/store";
import router from "@/router/index";

const api_base_url = process.env.VUE_APP_BASE_API_URL;
const api_v2_base_url = process.env.VUE_APP_BASE_API_V2_URL;

export const generateOTP = (username, deliveryMethod) => {
  return axios.post(`${api_base_url}/auth/otp/`, {
    entity_code: username,
    delivery: deliveryMethod,
  });
};

export const employeeGenerateOTP = (username, deliveryMethod) => {
  return axios.post(`${api_base_url}/employees/auth/otp/`, {
    username: username,
    delivery: deliveryMethod,
  });
};

export const login = (username, password, deliveryMethod) => {
  return axios.post(`${api_base_url}/auth/login/`, {
    entity_code: username,
    password: password,
    delivery: deliveryMethod,
  });
};

export const employeeLogin = (username, password, deliveryMethod) => {
  return axios.post(`${api_base_url}/employees/auth/login/`, {
    username: username,
    password: password,
    delivery: deliveryMethod,
  });
};

export const logout = () => {
  return axios.post(`${api_base_url}/auth/logout/`);
};

export const users = () => {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + store.getters.user_token;
  return axios.get(process.env.API_URL + "/users/");
};

export const getUser = (username) => {
  return axios.get(`${api_base_url}/entities/by-code/${username}/`, {
    headers: { Authorization: `token ${store.getters.user_token}` },
  });
};

export const getUserProfile = () => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/profiles/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getCashHoldings = () => {
  return axios.get(
    `${api_v2_base_url}/entities/${store.getters.user_pk}/cash-holdings/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getHoldingPeriodData = (startDate) => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/holding-data-period/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
      params: {
        year: startDate.year,
        month: startDate.month,
      },
    }
  );
};

export const getAdvisor = () => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/employees/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getDocuments = () => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/documents/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getDocument = (documentId) => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/documents/${documentId}/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
      responseType: "blob",
    }
  );
};

export const getInvestments = () => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/investments/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getPortfolio = () => {
  return axios.get(
    `${api_v2_base_url}/entities/${store.getters.user_pk}/portfolios/`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getPortfolioValuation = () => {
  return axios.get(
    `${api_base_url}/entities/${store.getters.user_pk}/portfolios/valuation-over-time`,
    {
      headers: { Authorization: `token ${store.getters.user_token}` },
    }
  );
};

export const getPromotions = async () => {
  return await {
    data: [
      {
        id: 1,
        title: "",
        description: "",
        background: "https://assets.carrickconcierge.com/banner6.jpg",
        link: "https://www.carrick-property.com/",
      },
      {
        id: 2,
        title: "",
        description: "",
        background: "https://assets.carrickconcierge.com/Banner3.jpg",
        link: "https://www.carrick-consult.com/",
      },
    ],
  };
};

export const clearCurrentSession = () => {
  store.dispatch("AUTH_LOGOUT").then(() => {
    router.push("/login");
    store
      .dispatch("NOTIFICATION", {
        text: "401: Unauthorized access",
        class: "wk-alert",
      })
      .then(() => {});
  });
};

export const setEntityContactDetails = (payload) => {
  return axios.put(
    `${api_base_url}/entities/${store.getters.user_pk}/profiles/update`,
    payload,
    {
      headers: {
        Authorization: `token ${store.getters.user_token}`,
      },
    }
  );
};

export const sendMessage = (payload) => {
  return axios.post(
    `${api_base_url}/entities/${store.getters.user_pk}/employees/messages/`,
    payload,
    {
      headers: {
        Authorization: `token ${store.getters.user_token}`,
      },
    }
  );
};

export const getCurrencyRates = () => {
  return axios.get(`${api_base_url}/currency-rates/`, {
    headers: { Authorization: `token ${store.getters.user_token}` },
  });
};

export const uploadDocuments = async (payload) => {
  await wait(500);
  return axios.post(
    `${api_base_url}/entities/${store.getters.user_pk}/documents/upload/`,
    payload,
    {
      headers: {
        Authorization: `token ${store.getters.user_token}`,
      },
    }
  );
};

export const getLastUpdated = async () => {
  return axios.get(`${api_base_url}/last-updated/`, {
    headers: { Authorization: `token ${store.getters.user_token}` },
  });
};
