<template>
  <div :class="['desktop-navigation', this.isOpen === true ? 'is-open' : '']">
    <div :class="['--fixed', this.isOpen === true ? 'is-open' : '']">
      <div class="branding--container">
        <img
          class="branding--image"
          src="@/assets/images/carrick_logo.svg"
          alt=""
        />
      </div>

      <transition name="fade">
        <div
          class="active-entity--text"
          v-show="activeEntity !== undefined && isOpen === true"
        >
          {{ activeEntity }}
        </div>
      </transition>

      <button
        class="open-navigation--button"
        :class="[
          'open-navigation--button',
          this.isOpen === true ? '' : '--add-bg-color',
        ]"
        id="openNavigationButton"
        title="Open the navigation panel"
        v-on:click="() => toggleOpen(!isOpen)"
      >
        <font-awesome-icon v-if="isOpen === false" icon="chevron-right" />
        <font-awesome-icon v-if="isOpen === true" icon="chevron-left" />
      </button>

      <div class="links--container">
        <button
          :class="[
            'link--container',
            active === `${link.name}` ? 'link-active' : '',
          ]"
          :id="`${link.name}Button`"
          :title="`Go to the ${link.name} page`"
          v-on:click="() => onLinkClicked(link)"
          v-for="link in links"
          :key="link"
        >
          <font-awesome-icon
            :class="[
              'link--icon',
              active === `${link.name}` ? 'link-active' : '',
            ]"
            :icon="`${link.icon}`"
          />

          <transition name="fade">
            <p
              :id="`${link.name}Text`"
              :class="[active === `${link.name}` ? 'link-active' : '']"
              v-show="isOpen === true"
            >
              {{ link.niceName }}
            </p>
          </transition>
        </button>
      </div>

      <small class="git_version">
        {{ gitVersion }}
      </small>

      <div class="logout--container">
        <button
          class="link--container"
          id="logoutButton"
          title="Logout of site"
          v-on:click="onLogoutClicked"
        >
          <p class="link-active" v-if="isOpen === true">Logout</p>
          <font-awesome-icon
            class="link--icon link-active"
            icon="sign-out-alt"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopNavigation",

  props: [
    "activeEntity",
    "active",
    "links",
    "onLinkClicked",
    "onLogoutClicked",
    "toggleOpen",
    "isOpen",
  ],

  data() {
    return {
      gitVersion: "",
    };
  },

  mounted() {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      this.gitVersion = GIT_DESCRIBE.hash;
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === "NOTIFICATION") {
        if (mutation.payload && mutation.payload.text) {
          this.$wkToast(mutation.payload.text, {
            className: mutation.payload.class,
          });
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/navigation/desktop";
</style>
