<template>
  <div
    :class="['currency-selector--container', expand === true ? 'expanded' : '']"
  >
    <ul class="currency--list">
      <li v-for="currency in currencies" :key="currency">
        <p v-on:click="() => setCurrency(currency)">{{ currency }}</p>
      </li>
    </ul>

    <button
      title="Show available currencies"
      class="currency-selector--button"
      v-on:click="showCurrencies"
    >
      {{ this.$store.getters.currency }}
    </button>
  </div>
</template>

<script>
import { floatingButtonClicked } from "@/utils/analytics.js";
export default {
  data() {
    return { expand: false, currencies: ["GBP", "USD", "EUR", "ZAR"] };
  },

  methods: {
    showCurrencies() {
      this.expand = !this.expand;

      const routeName = this.$router.currentRoute.value.name;

      floatingButtonClicked({
        action: "toggled",
        name: "Currency Selector",
        routeName,
      });
    },

    setCurrency(currency) {
      this.$store.dispatch("SET_CURRENCY", currency);
      const routeName = this.$router.currentRoute.value.name;

      floatingButtonClicked({
        action: "clicked",
        name: "Currency Selector",
        currency,
        routeName,
      });

      this.expand = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/currencyselector/currencyselector";
</style>
