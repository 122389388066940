<template>
  <div>
    <img
      class="login-branding--image"
      id="loginBrandingImage"
      src="@/assets/images/carrick_logo.png"
      alt="Carrick. | Concierge"
    />
    <h1>Dear Client.</h1>
    <h2>Please note that this section is currently under maintenance.</h2>
    <h3>We are working on updates to bring you a euphoric experience soon.</h3>
  </div>
</template>

<script>
export default {
  name: "WIP",
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
@import "@/assets/styles/components/wip/wip";
</style>
