<template>
  <div class="progressbar--container">
    <div class="text--container">
      <p class="percentage--text">{{ percentageValue }}%</p>
    </div>
    <div
      class="bar--container"
      :style="`width: ${percentageValue > 0 ? percentageValue : 0}%`"
    ></div>
  </div>
</template>

<script>
import { cleanMoney } from "@/utils/string_helpers";

export default {
  props: ["percentageValue"],

  methods: {
    cleanMoney,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
@import "@/assets/styles/components/progressbar/progressbar";
</style>
