<template>
  <div class="input--group" id="inputGroup">
    <label :id="`${name}Label`" :for="name">{{ label }}</label>

    <div
      :class="[type === 'tel' ? 'input--container' : 'input-single--container']"
      id="inputContainer"
    >
      <select
        id="countryCodeSelect"
        v-model="countryCode"
        v-if="type === 'tel'"
      >
        <option
          :id="`${option.code}Option`"
          v-for="option in countryCodes"
          :value="option.code"
          :key="option.code"
        >
          {{ option.country }}: {{ option.code }}
        </option>
      </select>

      <input
        class="custom--input"
        :id="`${name}Input`"
        :name="name"
        :type="type"
        :disabled="disabled"
        v-model="formatedData"
        :placeholder="placeholder"
        v-on:input="onChange"
        ref="input"
        v-on:keyup.enter="() => $emit('submit')"
      />
    </div>

    <small class="input-error" id="error">{{ error }}</small>
  </div>
</template>

<script>
import { getCountries, getCountryCallingCode } from "libphonenumber-js";

export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String, default: "" },
    type: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    autofocus: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    pattern: { type: String, default: "" },
    required: { type: Boolean, default: false },
    value: { type: String },
    error: { type: String, default: "" },
    valid: { type: Boolean, default: true },
    data: { default: "" },
    validate: { type: Boolean, default: false },
  },

  data() {
    return { countryCode: "", formatedData: "" };
  },

  methods: {
    onChange(e) {
      if (this.type === "tel") {
        this.emitData({
          extension: this.countryCode,
          number: e.target.value,
        });
      } else if (this.type === "email") {
        this.emitData({
          email: e.target.value,
        });
      } else {
        this.emitData(e.target.value);
      }
    },

    emitData(data) {
      this.$emit("on-value-changed", data);
    },
  },

  watch: {
    countryCode: function () {
      this.emitData({ extension: this.countryCode, number: this.formatedData });
    },
  },

  mounted() {
    if (this.autofocus) this.$nextTick(() => this.$refs.input.focus());

    if (this.type === "tel" && this.data.length > 0) {
      let telephone = this.data.split(".");
      this.countryCode = telephone[0].trim();
      this.formatedData = `0${telephone[1]}`;
    }

    if (
      (this.type === "email" || this.type === "username") &&
      this.data.length > 0
    ) {
      this.formatedData = this.data;
    }
  },

  computed: {
    countryCodes: function () {
      return getCountries().map((country) => {
        return { country, code: `+${getCountryCallingCode(country)}` };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
@import "@/assets/styles/settings.scss";
@import "@/assets/styles/components/input/input.scss";
</style>
