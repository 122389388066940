export default {
  SET_CURRENCY_SELECTOR_STATE({ commit }, payload) {
    commit("SET_CURRENCY_SELECTOR", payload);
  },

  SET_POLICY_SELECTOR_STATE({ commit }, payload) {
    commit("SET_POLICY_SELECTOR", payload);
  },

  SET_PRODUCTS_GAIN_LOSS_STATE({ commit }, payload) {
    commit("SET_PRODUCTS_GAIN_LOSS", payload);
  },
};
