import _ from "lodash";
export const getExtension = (filename) => {
  if (filename) {
    return filename.split(".").pop();
  }
  return "";
};

export const getItemIcon = (filename) => {
  const extension = getExtension(filename);
  switch (extension) {
    case "xls":
      return "file-excel";
    case "xlsx":
      return "file-excel";
    case "doc":
      return "file-word";
    case "docx":
      return "file-word";
    case "ppt":
      return "file-powerpoint";
    case "pptx":
      return "file-powerpoint";
    case "pps":
      return "file-powerpoint";
    case "ppsx":
      return "file-powerpoint";
    case "eml":
      return "envelope";
    case "msg":
      return "envelope";
    case "pdf":
      return "file-pdf";
    case "zip":
      return "file-archive";
    case "xml":
      return "file-code";
    case "mp3":
      return "file-audio";
    case "wav":
      return "file-audio";
    default:
      return "folder-minus";
  }
};

export const displayDocument = (filename) => {
  const excluded_document_types = [
    "CR", // Call Recording
    "CRC", // Case Requirements Checklist
    "CDDF", // Client Due Diligence Form
    "COBF", // Client On-Boarding Form
    "DAF", // Deviation Acceptance Form
    "EC", // Electronic Communication (Email)
    "SL", // FIC/UN Sanctions List
    "GD", // General Documents
    "ROBF", // Risk Onboarding Form
  ];

  const excluded_extensions = [
    "msg", // Email messages
  ];

  const regex = /[A-Z]{3}\d{6}_([A-Z]+)_\d{14}\.(\w+)/;

  const matches = filename.name.match(regex);

  if (matches) {
    return !(
      _.includes(excluded_document_types, matches[1]) ||
      _.includes(excluded_extensions, matches[2])
    );
  }

  return true;
};

export const filterTree = (list) => {
  return _.filter(list, (item) => {
    if (item.get_children.length > 0) {
      item.get_children = filterTree(item.get_children);
    }

    return displayDocument(item);
  });
};
