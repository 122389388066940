import _ from "lodash";

export default {
  user_pk: (state) => state.user_pk,
  user_profile: (state) => state.user_profile,
  user_search: (state) => state.user_search,
  currency: (state) => state.currency,
  policy: (state) => state.policy,
  policies: (state, getters) => {
    return ["all", ..._(getters.portfolios).map("policy_number").uniq()];
  },
  policySelectorDisplay: (state, getters) => (policyNumber) => {
    if (policyNumber === "all") return policyNumber;
    for (let policy of getters.portfolios) {
      if (policy.policy_number == policyNumber) {
        return `${policy.provider} - ${policyNumber}`;
      }
    }
  },
  user_full_name: (state) => {
    if (state.user_profile !== undefined) {
      return `${state.user_profile.first_name} ${state.user_profile.last_name}`;
    }
    return undefined;
  },
  hasPortfolio: (state) => state.hasPortfolio,
};
