<template>
  <div class="date-picker-main--container" id="datePickerMainContainer">
    <button
      id="showDateWindowButton"
      title="Select the month you would like to view"
      v-on:click="onShowWindowClick"
    >
      {{ buttonText }}
    </button>

    <!-- FLOATING WINDOW -->
    <div
      class="date-picker-window-background--container"
      id="datePickerWindowBackgroundContainer"
      v-if="showWindow === true"
      v-on:click="onShowWindowClick"
    ></div>
    <div
      class="date-picker-window--container"
      id="datePickerWindowContainer"
      v-if="showWindow === true"
    >
      <!-- YEAR -->
      <YearPicker
        v-if="activeView === 'year' && showYear === true"
        :year="activeYear"
        :setView="setView"
        :setYear="setYear"
      />

      <!-- MONTH -->
      <MonthPicker
        v-if="activeView === 'month' && showMonth === true"
        :year="activeYear"
        :month="activeMonth"
        :setView="setView"
        :setMonth="setMonth"
      />

      <!-- DAY -->
      <DayPicker
        v-if="activeView === 'day' && showDay === true"
        :year="activeYear"
        :month="activeMonth"
        :day="activeDay"
        :setView="setView"
        :setDay="setDay"
      />
    </div>
  </div>
</template>

<script>
import YearPicker from "./_YearPicker";
import MonthPicker from "./_MonthPicker";
import DayPicker from "./_DayPicker";

export default {
  name: "DatePicker",
  components: { YearPicker, MonthPicker, DayPicker },
  mounted() {
    this.setStartView();
  },

  props: {
    showYear: { type: Boolean, default: true },
    showMonth: { type: Boolean, default: true },
    showDay: { type: Boolean, default: true },
    getDateMethod: { type: Function },
    buttonText: { default: "Set Date" },
  },

  data() {
    return {
      showWindow: false,
      activeView: "year",
      activeYear: null,
      activeMonth: null,
      activeDay: null,
    };
  },

  methods: {
    setStartView() {
      if (this.showYear === false) this.activeView = "month";
      if (this.showMonth === false) this.activeView = "day";
    },

    onShowWindowClick() {
      this.showWindow = !this.showWindow;
      if (this.showWindow === false) this.activeView = "year";
    },

    setView(view) {
      if (this.showYear === true && view === "year") this.activeView = view;
      if (this.showMonth === true && view === "month") this.activeView = view;
      if (this.showDay === true && view === "day") this.activeView = view;
    },

    setYear(year) {
      this.activeYear = year;
      if (this.showMonth === false) this.showWindow = false;
      if (this.showWindow === false) this.activeView = "year";
      this.getDate();
    },

    setMonth(month) {
      this.activeMonth = month;
      if (this.showDay === false) this.showWindow = false;
      if (this.showWindow === false) this.activeView = "year";
      this.getDate();
    },

    setDay(day) {
      this.activeDay = day;
      this.showWindow = false;
      if (this.showWindow === false) this.activeView = "year";
      this.getDate();
    },

    getDate() {
      const dateDate = {
        year: this.activeYear,
        month: this.activeMonth,
        day: this.activeDay,
      };

      this.$emit("set-date", dateDate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/datepicker/datepicker";
</style>
