<template>
  <div class="title--container" id="titleContainer">
    <h2 class="title--text" id="titleText">{{ text }}</h2>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";
@import "@/assets/styles/components/general/title-text.scss";
</style>
