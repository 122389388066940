import { getDocuments, clearCurrentSession } from "@/utils/api";

export default {
  DOCUMENTS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.documents && state.documents.length > 0) {
        resolve(state.documents);
      } else {
        getDocuments()
          .then((resp) => {
            commit("DOCUMENTS", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
