import _ from "lodash";
import { percentageOf } from "@/utils/math_helpers";

export default {
  holdings: (state, getters) => {
    const portfolios = getters.sortedPortfolios;
    const grouped = _.groupBy(portfolios, "product_category");
    const mapped = _.map(grouped, (products, product_category) => ({
      product_category: product_category,
      gbp_sum: _(products).map("gbp_amount").map(parseFloat).sum(),
      usd_sum: _(products).map("usd_amount").map(parseFloat).sum(),
      eur_sum: _(products).map("eur_amount").map(parseFloat).sum(),
      zar_sum: _(products).map("zar_amount").map(parseFloat).sum(),
    }));
    return mapped;
  },
  other: (state, getters) => {
    const holdings = [...getters.holdings];
    return holdings
      .filter((el) => el.product_category !== "Cash")
      .map((el) => el[`${state.currency.toLowerCase()}_sum`])
      .reduce((a, b) => {
        return a + b;
      }, 0);
  },
  cash: (state, getters) => {
    const holdings = [...getters.holdings];
    return holdings
      .filter((el) => el.product_category === "Cash")
      .map((el) => el[`${state.currency.toLowerCase()}_sum`])
      .reduce((a, b) => {
        return a + b;
      }, 0);
  },
  total: (state, getters) => {
    const holdings = [...getters.holdings];
    return holdings
      .map((el) => el[`${state.currency.toLowerCase()}_sum`])
      .reduce((a, b) => {
        return a + b;
      }, 0);
  },

  cashPercentage: (state, getters) => {
    const percentage = percentageOf(getters.cash, getters.total).toFixed(2);
    if (isNaN(percentage)) return "0.00";
    return percentage;
  },

  otherPercentage: (state, getters) => {
    return percentageOf(getters.other, getters.total).toFixed(2);
  },
};
