<template>
  <div class="loader-widget-loader--container" id="widgetLoaderContainer">
    <Loader size="250px" :color="'#881534'" />
    <p v-if="text">{{ text }}</p>
  </div>
</template>

<script>
import Loader from "vue-spinner/src/ScaleLoader.vue";
export default {
  name: "WidgetLoader",
  props: ["text"],
  components: { Loader },
};
</script>

<style lang="scss">
.loader-page-loader--container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 50vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
