<template>
  <PageLoader v-if="loading" />

  <template v-else>
    <div v-if="hasPortfolio" class="profile--container">
      <div class="promotions"><Promotions /></div>

      <div class="content--container">
        <div class="glance-cards">
          <GlanceCard
            class="grid-box glance-card-a"
            :icon="'money-bill-wave'"
            :title="'Cash Investments'"
            :value="cash"
          />
          <GlanceCard
            class="grid-box glance-card-b"
            :icon="'file-invoice-dollar'"
            :title="'Holdings Value'"
            :value="other"
          />
          <GlanceCard
            class="grid-box glance-card-c"
            :icon="'chart-pie'"
            :title="'Total Value'"
            :value="total"
          />
        </div>

        <div class="grid-box holdings-breakdown"><HoldingsTable /></div>

        <div class="small-chart--container">
          <div class="grid-box provider-breakdown">
            <PortfolioProviderBreakdown />
          </div>
          <div class="grid-box risk-chart">
            <RiskChart :riskScore="risk_profile" />
          </div>
        </div>

        <div class="grid-box user-details">
          <div class="user-details--container">
            <h3 class="user-name">{{ fullName }}</h3>

            <div class="item-group-single">
              <font-awesome-icon icon="map-marker-alt" />
              <p>{{ location }}</p>
            </div>

            <h3 class="item-title">Contact Information</h3>

            <div v-if="homeNumber.value" class="item-group">
              <p>Home Number:</p>
              <p>{{ cleanPhoneNumber(homeNumber.value) }}</p>
            </div>

            <div v-if="mobileNumber.value" class="item-group">
              <p>Mobile Number:</p>
              <p>{{ cleanPhoneNumber(mobileNumber.value) }}</p>
            </div>

            <div v-if="workNumber.value" class="item-group">
              <p>Work Number:</p>
              <p>{{ cleanPhoneNumber(workNumber.value) }}</p>
            </div>

            <div v-if="homeEmail.value" class="item-group">
              <p>Home Email:</p>
              <p>{{ homeEmail.value }}</p>
            </div>

            <div v-if="workEmail.value" class="item-group">
              <p>Work Email:</p>
              <p>{{ workEmail.value }}</p>
            </div>

            <button
              class="secondary-edit--button"
              id="editContactDetailsButton"
              @click="showUpdateModal"
            >
              Edit Details
            </button>

            <h3 class="item-title">Other Information</h3>

            <div v-if="nationality" class="item-group">
              <p>Nationality:</p>
              <p>{{ nationality }}</p>
            </div>

            <div v-if="birth_date" class="item-group">
              <p>Date of Birth:</p>
              <p>{{ birth_date }}</p>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="showModal" v-on:hide="hideModal">
        <h2>Please enter your new details</h2>

        <!-- HOME NUMBER -->
        <Input
          :label="'Home Phone'"
          :name="'telephone'"
          :type="'tel'"
          :data="homeNumber.value"
          :error="homeNumber.error"
          :valid="homeNumber.valid"
          :validate="true"
          :placeholder="'Please enter your new home number'"
          v-on:onValueChanged="
            (resp) => {
              onInputChanged(homeNumber, resp);
            }
          "
        />

        <!-- MOBILE NUMBER -->
        <Input
          :label="'Mobile Phone'"
          :name="'telephone'"
          :type="'tel'"
          :data="mobileNumber.value"
          :error="mobileNumber.error"
          :valid="mobileNumber.valid"
          :validate="true"
          :placeholder="'Please enter your new mobile number'"
          v-on:onValueChanged="
            (resp) => {
              onInputChanged(mobileNumber, resp);
            }
          "
        />

        <!-- WORK NUMBER -->
        <Input
          :label="'Work Phone'"
          :name="'telephone'"
          :type="'tel'"
          :data="workNumber.value"
          :error="workNumber.error"
          :valid="workNumber.valid"
          :validate="true"
          :placeholder="'Please enter your new work number'"
          v-on:onValueChanged="
            (resp) => {
              onInputChanged(workNumber, resp);
            }
          "
        />

        <!-- HOME EMAIL -->
        <Input
          :label="'Home Email'"
          :name="'email'"
          :type="'email'"
          :data="homeEmail.value"
          :error="homeEmail.error"
          :valid="homeEmail.valid"
          :validate="true"
          :placeholder="'Please enter your new home email'"
          v-on:onValueChanged="
            (resp) => {
              onInputChanged(homeEmail, resp);
            }
          "
        />

        <!-- WORK EMAIL -->
        <Input
          :label="'Work Email'"
          :name="'email'"
          :type="'email'"
          :data="workEmail.value"
          :error="workEmail.error"
          :valid="workEmail.valid"
          :validate="true"
          :placeholder="'Please enter your new work email'"
          v-on:onValueChanged="
            (resp) => {
              onInputChanged(workEmail, resp);
            }
          "
        />
        <button v-on:click="updateContactDetails" id="updateContactDetails">
          Submit
        </button>
        <button
          class="secondary--button"
          id="hideModalButton"
          v-on:click="hideModal"
        >
          Close
        </button>
      </Modal>
      <Note text="Please note that due to several factors including delays in receipt of data and currency conversion rates, valuations may differ from those on provider portals."/>
      <Note text="We do not currently receive data feeds from all providers. Please see the &quot;Providers&quot; section for more information."/>
    </div>

    <div v-else>
      <MissingProfile />
    </div>
  </template>
</template>

<script>
// COMPONENTS
import PageLoader from "@/components/loaders/PageLoader";
import GlanceCard from "@/components/glancecard/GlanceCard";
import RiskChart from "@/components/homeUser/RiskChart.vue";
import HoldingsTable from "@/components/homeUser/HoldingsTable.vue";
import PortfolioProviderBreakdown from "@/components/homeUser/PortfolioProviderBreakdown";
import Promotions from "@/components/promotions/Promotions";
import Modal from "@/components/modal/Modal";
import MissingProfile from "@/components/missingprofile/MissingProfile";
import Input from "@/components/input/Input";
import Note from "@/components/general/Note";

// SCRIPTS
import { cleanPhoneNumber, cleanMoney } from "@/utils/string_helpers";
import {
  validateTelephoneNumber,
  validateEmailAddress,
} from "@/utils/validators";
import { setEntityContactDetails, clearCurrentSession } from "@/utils/api";

export default {
  name: "Home",

  components: {
    PageLoader,
    GlanceCard,
    Modal,
    MissingProfile,
    Input,
    RiskChart,
    Promotions,
    PortfolioProviderBreakdown,
    HoldingsTable,
    Note,
  },

  data() {
    return {
      loading: true,
      showModal: false,
      userData: {},
      fullName: "",
      homeNumber: { extension: "", value: "", valid: true, error: "" },
      mobileNumber: { extension: "", value: "", valid: true, error: "" },
      workNumber: { extension: "", value: "", valid: true, error: "" },
      homeEmail: { extension: "", value: "", valid: true, error: "" },
      workEmail: { extension: "", value: "", valid: true, error: "" },
      location: "",
      nationality: "",
      birth_date: "",
      risk_profile: "",
    };
  },

  mounted() {
    const userProfilePromise = this.$store.dispatch("USER_PROFILE");
    const cashHoldingsPromise = this.$store.dispatch("CASH_HOLDING", {});
    const holdingsPromise = this.$store.dispatch("HOLDINGS", {});
    const portfoliosPromise = this.$store.dispatch("PORTFOLIOS", {});
    Promise.all([
      userProfilePromise,
      holdingsPromise,
      cashHoldingsPromise,
      portfoliosPromise,
    ])
      .then((resp) => {
        this.setProfileData(resp[0]);
        this.loading = false;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.loading = false;
          this.$store.dispatch("HAS_PORTFOLIO", false);
        }
      });
  },

  computed: {
    hasPortfolio() {
      return this.$store.getters.hasPortfolio;
    },

    currency() {
      return this.$store.getters.currency;
    },

    cash() {
      return cleanMoney(this.$store.getters.cash, this.currency);
    },

    other() {
      return cleanMoney(this.$store.getters.other, this.currency);
    },

    total() {
      return cleanMoney(this.$store.getters.total, this.currency);
    },
  },

  methods: {
    cleanMoney,
    cleanPhoneNumber,
    validateTelephoneNumber,
    validateEmailAddress,

    onInputChanged(attribute, resp) {
      // TELEPHONE FUNCTIONALITY
      if (resp.number) {
        const validData = this.validateTelephoneNumber(resp.number);
        attribute.valid = validData.status;
        attribute.error = validData.error;
        attribute.value = `${resp.extension}.${resp.number.replace(0, "")}`;
        attribute.extension = resp.extension;

        if (!resp.extension) {
          attribute.valid = false;
          attribute.error = "No country code selected";
        }
      } else if (!resp.number) {
        attribute.valid = true;
        attribute.error = "";
        attribute.value = "";
        attribute.extension = "";
      }

      // EMAIL FUNCTIONALITY
      if (resp.email) {
        const validData = this.validateEmailAddress(resp.email);
        attribute.valid = validData.status;
        attribute.error = validData.error;
        attribute.value = resp.email;
      }
    },

    showUpdateModal() {
      this.showModal = true;
    },

    hideModal() {
      this.showModal = false;
    },

    updateContactDetails() {
      let isValid = true;
      let isChanged = false;

      const inputs = [
        this.homeNumber,
        this.mobileNumber,
        this.workNumber,
        this.homeEmail,
        this.workEmail,
      ];

      for (let attribute of inputs) {
        if (attribute.original.length > 0 && attribute.value.length === 0) {
          attribute.error =
            "Unable to remove value, you are only able to update values";
          attribute.valid = false;
          attribute.value = attribute.original;
        }
        if (!attribute.valid) {
          isValid = false;
        }
        if (attribute.original != attribute.value) isChanged = true;
      }

      if (!isValid) {
        this.$wkToast("Please check the form for errors", {
          className: "wk-alert",
        });
        return;
      }

      if (!isChanged) {
        this.$wkToast("Nothing to update", {
          className: "wk-warn",
        });
        return;
      }
      const payload = {
        home_number: this.homeNumber.value,
        mobile_number: this.mobileNumber.value,
        work_number: this.workNumber.value,
        home_email: this.homeEmail.value,
        work_email: this.workEmail.value,
      };

      setEntityContactDetails(payload)
        .then((resp) => {
          this.setProfileData(resp.data);
          this.$wkToast("Contact details updated", {
            className: "wk-info",
          });
          this.hideModal();
        })
        .catch((err) => {
          if (err.response.status === 401) clearCurrentSession();
          this.$wkToast(err, {
            className: "wk-alert",
          });
        });
    },

    setProfileData(data) {
      this.userData = data;
      this.fullName = `${data.first_name} ${data.last_name}`;
      this.location = data.location;
      this.nationality = data.nationality;
      this.birth_date = data.birth_date;
      this.risk_profile = data.risk_profile ? parseInt(data.risk_profile) : 0;

      this.homeNumber = {
        value: data.home_number,
        original: data.home_number,
        error: "",
        valid: true,
      };

      this.mobileNumber = {
        value: data.mobile_number,
        original: data.mobile_number,
        error: "",
        valid: true,
      };

      this.workNumber = {
        value: data.work_number,
        original: data.work_number,
        error: "",
        valid: true,
      };

      this.homeEmail = {
        value: data.home_email,
        original: data.home_email,
        error: "",
        valid: true,
      };

      this.workEmail = {
        value: data.work_email,
        original: data.work_email,
        error: "",
        valid: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";
@import "@/assets/styles/views/home";
</style>
