import { employeeLogin, login, clearCurrentSession } from "@/utils/api";
import { addTime } from "@/utils/date_helpers.js";

const tokenAge = 7;

export default {
  AUTH_REQUEST({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      login(payload.username, payload.password, payload.deliveryMethod)
        .then((resp) => {
          const token = resp.data.token;
          const tokenCreated = resp.data.token_created;
          const tokenExpiry = addTime(tokenCreated, tokenAge, "hours");
          const entityId = resp.data.entity_id;

          commit("USER", entityId);
          commit("AUTH_SUCCESS", { token, tokenExpiry });
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_ERROR");
          if (err.response.status === 401) clearCurrentSession();
          reject(err);
        });
    });
  },

  EMPLOYEE_AUTH_REQUEST({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("EMPLOYEE_AUTH_REQUEST");
      employeeLogin(payload.username, payload.password, payload.deliveryMethod)
        .then((resp) => {
          const token = resp.data.token;
          const tokenCreated = resp.data.token_created;
          const tokenExpiry = addTime(tokenCreated, tokenAge, "hours");
          const entityId = "";

          commit("USER", entityId);
          commit("AUTH_SUCCESS", { token, tokenExpiry });
          commit("EMPLOYEE_AUTH_SUCCESS");
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_ERROR");
          if (err.response.status === 401) clearCurrentSession();
          reject(err);
        });
    });
  },

  TOKEN_LOGIN({ commit }, payload) {
    const { token, tokenCreated, entityCode } = payload;
    const tokenExpiry = addTime(tokenCreated, tokenAge, "hours");

    localStorage.setItem("token-expiry", tokenExpiry);
    localStorage.setItem("user-token", token);
    localStorage.setItem("user-session", true);

    commit("AUTH_SUCCESS", { token, tokenExpiry });
    commit("EMPLOYEE_AUTH_SUCCESS");
    commit("SET_USER_SEARCH", entityCode);
  },

  AUTH_LOGOUT({ commit, dispatch }) {
    return new Promise((resolve) => {
      localStorage.removeItem("user-session");
      localStorage.removeItem("user-currency");
      localStorage.removeItem("user-policy");
      localStorage.removeItem("user-search");
      localStorage.removeItem("user-pk");
      localStorage.removeItem("user-token");
      localStorage.removeItem("token-expiry");
      dispatch("CLEAR_ALL_DATA");
      commit("AUTH_LOGOUT");
      resolve();
    });
  },
};
