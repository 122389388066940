<template>
  <div v-if="item">
    <div class="tree-item--divider"></div>

    <div class="tree-list--item" @click="toggle">
      <span v-if="isFolder">
        <font-awesome-icon class="tree--icon" icon="folder" v-if="!isOpen" />
        <font-awesome-icon
          class="tree--icon"
          icon="folder-open"
          v-else-if="isOpen"
        />
      </span>

      <span v-else>
        <font-awesome-icon class="tree--icon" :icon="itemIcon" />
      </span>

      <div class="tree--group">
        <p class="tree--title">{{ item.name }}</p>

        <p class="tree--description" v-if="isFolder">
          Files :{{ item.get_children.length }}
        </p>
      </div>

      <small class="information--text" v-if="isFolder && !isOpen"
        >click a folder to expand</small
      >
    </div>

    <div class="tree-list" v-show="isOpen" v-if="isFolder">
      <TreeItem
        v-for="(child, index) in item.get_children"
        :key="index"
        :item="child"
      />
    </div>
  </div>
</template>

<script>
// SCRIPTS
import { getDocument } from "@/utils/api";
import { getItemIcon } from "@/utils/document_helpers";

export default {
  props: {
    item: Object,
  },

  data: function () {
    return {
      isOpen: false,
    };
  },

  computed: {
    isFolder() {
      return this.item.get_children && this.item.get_children.length;
    },

    itemIcon() {
      if (!this.isFolder) {
        return getItemIcon(this.item.name);
      }
      return "file";
    },
  },

  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        getDocument(this.item.id).then((resp) => {
          const blob = new Blob([resp.data], {
            type: resp.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.item.name;
          link.click();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/documents/tree";
</style>
