import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast from "vue3-toast-single";
import "vue3-toast-single/dist/toast.css";

import VueApexCharts from "vue3-apexcharts";
import HighchartsVue from "highcharts-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faChartLine,
  faChartBar,
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHome,
  faMapMarkerAlt,
  faPhone,
  faMobile,
  faEnvelope,
  faUsers,
  faBars,
  faSearch,
  faCircle,
  faBook,
  faMoneyBill,
  faFolder,
  faFolderOpen,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileArchive,
  faFileCode,
  faFileAudio,
  faTrash,
  faPen,
  faHandHoldingUsd,
  faInfoCircle,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faChartPie,
  faLandmark,
  faWrench,
  faCog,
  faCloudUploadAlt,
  faFolderMinus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faUserCircle,
  faChartLine,
  faChartBar,
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHome,
  faMapMarkerAlt,
  faPhone,
  faMobile,
  faEnvelope,
  faUsers,
  faBars,
  faSearch,
  faCircle,
  faBook,
  faMoneyBill,
  faFolder,
  faFolderOpen,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileArchive,
  faFileCode,
  faFileAudio,
  faTrash,
  faPen,
  faHandHoldingUsd,
  faInfoCircle,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faChartPie,
  faLandmark,
  faWrench,
  faCog,
  faCloudUploadAlt,
  faFolderMinus
);

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(VueApexCharts)
  .use(HighchartsVue)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
