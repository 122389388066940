<template>
  <div class="row-group" id="rowGroup">
    <p class="title clickable" id="onDateClick" v-on:click="onDateClick">
      {{ activeDay }} {{ month }} {{ year }}
    </p>
  </div>
  <div class="divider"></div>
  <div class="date-picker--items --day" id="datePickerItemsDay">
    <div
      v-for="day in days"
      :class="[
        'date-picker--container',
        activeDay === day ? 'active-item' : '',
      ]"
      id="datePickerContainer"
      v-bind:key="day"
      v-on:click="onDayClick(day)"
    >
      {{ day }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePickerDay",
  mounted() {
    this.getDatesOfMonth();
  },
  props: ["setView", "setDay", "year", "month", "day"],
  data() {
    return {
      activeDay: this.day === null ? moment().date() : this.day,
      days: [],
    };
  },
  methods: {
    getDatesOfMonth() {
      const days = [];
      const dates = moment(
        `${this.year}-${this.month}`,
        "YYYY-MMMM"
      ).daysInMonth();
      for (const i in [...Array(dates).keys()]) {
        days.push(parseInt(i) + 1);
      }
      this.days = days;
    },
    onDateClick() {
      this.setView("month");
    },
    onDayClick(day) {
      this.activeDay = day;
      this.setDay(day);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/datepicker/datepicker";
</style>
