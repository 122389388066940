export const validateTelephoneNumber = (str) => {
  const lengthPattern = /[0-9]{10,}/;
  const alphaPattern = /[a-zA-Z]{1,}/;

  if (!str) return "";

  let regex = str.match(alphaPattern);

  if (regex)
    return { status: false, error: "Number contains alpha-numeric values" };

  regex = str.match(lengthPattern);
  if (regex) return { status: true, error: "" };
  return { status: false, error: "Number to short" };
};

export const validateEmailAddress = (str) => {
  // eslint-disable-next-line
  const pattern = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!str) return "";

  let regex = str.match(pattern);
  if (regex) return { status: true, error: "" };
  return { status: false, error: "Invalid email format" };
};
