<template>
  <div class="maintenance--container">
    <h2>{{ title }}</h2>
    <font-awesome-icon class="maintenance--icon" icon="wrench" />
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "message", "icon"],
};
</script>

<style lang="scss">
@import "@/assets/styles/views/maintenance.scss";
</style>
