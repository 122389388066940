export default {
  CASH_HOLDING(state, resp) {
    const cashProductCategory = resp.find(
      (el) => el.product_category === "Cash"
    );
    if (cashProductCategory !== undefined) {
      state.cash = cashProductCategory;
    } else {
      state.cash = 0;
    }
  },

  HOLDINGS(state, resp) {
    state.holdings = resp;
  },
};
