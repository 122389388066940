<template>
  <section id="accountPage">
    <PageLoader v-if="loading" />
    <div v-else>
      <PageTitle :text="'Account Summary'" />

      <Note
        :text="'The opening and closing values are calculated on the 8th of every month due to product provider data restrictions.'"
      />

      <HoldingPeriodGroup />
      <Note :text="'Cash is excluded from account summary.'" />
      <LastUpdated model="holdingsperformance" />
    </div>
  </section>
</template>

<script>
import PageTitle from "@/components/general/PageTitle";
import LastUpdated from "@/components/lastupdated/LastUpdated";
import Note from "@/components/general/Note";
import PageLoader from "@/components/loaders/PageLoader";
import HoldingPeriodGroup from "@/components/account/HoldingPeriodGroup";

export default {
  name: "Account",

  components: {
    PageTitle,
    HoldingPeriodGroup,
    LastUpdated,
    PageLoader,
    Note,
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    const lastUpdatedPromise = this.$store.dispatch("LAST_UPDATED", {});
    Promise.all([lastUpdatedPromise]).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss"></style>
