export const title = (str) => {
  const firstLetter = str[0];
  str = `${firstLetter.toUpperCase()}${str.replace(firstLetter, "")}`;
  return str;
};

export const cleanPhoneNumber = (str) => {
  if (!str) return "";
  const pattern =
    "/(?<ext>[0-9]{1,3}).(?<area>[0-9]{2})(?<middle>[0-9]{3})(?<end>[0-9]+)/";
  const regex = str.match(pattern);
  if (regex) {
    const data = regex.groups;
    return `+${data.ext} (0)${data.area} ${data.middle} ${data.end}`;
  }
  return str;
};

export const cleanMoney = (money, currency = "USD") => {
  if (isNaN(money)) money = 0;
  else if (!money) return money;

  return money.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
};
