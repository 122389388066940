<template>
  <form class="search--container" @submit.prevent="login">
    <div class="input--group">
      <h1>Search for a user profile</h1>
      <input
        id="searchUserButton"
        type="text"
        name="searchString"
        v-model="searchString"
      />
      <button
        id="submitButton"
        type="submit"
        name="searchButton"
        v-on:click="onSubmit"
      >
        Search
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "ProfileSearch",

  data() {
    return {
      searchString: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.checkStore();
    });
  },

  methods: {
    checkStore() {
      let searchString = this.$store.getters.user_search;
      if (searchString === "") return;
      if (this.$store.getters.user_profile) return;

      this.$store
        .dispatch("SET_USER", { code: searchString })
        .then(() => {
          try {
            this.$wkToast(`Using entity ${searchString}`);
          } catch (error) {
            console.warn("$wkToast not instantiated");
          }
          this.$router.go({ name: "Home" });
        })
        .catch(() => {
          try {
            this.$wkToast("Cannot use that profile", {
              className: "wk-alert",
            });
          } catch (error) {
            console.warn("$wkToast not instantiated");
          }
        });
    },

    onSubmit() {
      if (!this.searchString) return;
      this.$store
        .dispatch("SET_USER", { code: this.searchString })
        .then(() => {
          this.$wkToast(`Using entity ${this.searchString}`);
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          try {
            this.$wkToast("Cannot use that profile", { className: "wk-alert" });
          } catch (error) {
            console.warn("$wkToast not instantiated");
          }
        });
    },
  },
};
</script>

<style lang="scss">
.search--container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: 0 auto;
}
.input--group {
  button {
    margin-top: 10px !important;
  }
}
</style>
