<template>
  <Login :title="'Employee Login'" :entityType="'employee'" />
</template>

<script>
import Login from "@/components/login/Login";

export default {
  name: "Employee Login View",
  components: { Login },
};
</script>

<style lang="scss" scoped></style>
