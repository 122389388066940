const SplitFactory = require("@splitsoftware/splitio").SplitFactory;

const apiKey = process.env.VUE_APP_SPLITIO_KEY;

// Instantiate the SDK
const factory = SplitFactory({
  core: {
    authorizationKey: apiKey,
    // the key can be the logged in
    // user id, or the account id that
    // the logged in user belongs to.
    // The type of customer (user, account, custom)
    // is chosen during Split's sign-up process.
    key: "key",
  },
});
// And get the client instance you'll use
var client = factory.client();

export const getFeature = (callback) => {
  client.on(client.Event.SDK_READY, () => callback(client));
};
