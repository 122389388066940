<template>
  <div class="glance--container">
    <font-awesome-icon v-if="icon" class="icon" :icon="icon" />
    <p class="title">{{ title }}</p>
    <p class="normal">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "GlanceCard",

  props: ["icon", "title", "value"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";
@import "@/assets/styles/components/glancecard/glancecard";
</style>
