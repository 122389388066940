<template>
  <section id="advisorPage">
    <PageLoader v-if="loading" />

    <div v-else>
      <PageTitle :text="'Employee Information'" />

      <div class="information-cards--container" id="informationCardsContainer">
        <InformationCard
          :image="`${advisor[0].image}`"
          :data="advisor"
          :icon="'envelope'"
          v-for="advisor in advisors"
          :key="advisor"
          @iconClick="showModal(advisor)"
        />
      </div>
    </div>

    <AdvisorContactForm
      :advisor="messageAdvisor"
      :showModal="modalShowing"
      @hideModal="hideModal"
    />
  </section>
</template>

<script>
import _ from "lodash";
import { cleanPhoneNumber } from "@/utils/string_helpers";
import PageTitle from "@/components/general/PageTitle";
import InformationCard from "@/components/informationcard/InformationCard";
import PageLoader from "@/components/loaders/PageLoader";
import AdvisorContactForm from "@/components/advisors/ContactForm";

export default {
  name: "Advisor",

  components: { PageTitle, InformationCard, PageLoader, AdvisorContactForm },

  mounted() {
    this.$store.dispatch("ADVISOR").then(() => {
      this.loading = false;
    });
  },

  computed: {
    advisors: function () {
      const advisors = [];
      this.$store.getters.filteredAdvisors.forEach((element) => {
        advisors.push([
          {
            image: this.buildImageURL(element),
            title: `${element.first_name} ${element.last_name}`,
            style: "title",
            border: false,
            collapsible: false,
            data: [
              {
                style: "normal",
                value: element.position,
              },
            ],
          },
          {
            title: "Office Information",
            style: "subtitle",
            border: true,
            borderStyle: "borderThin",
            collapsible: true,
            data: [
              {
                label: "Office",
                style: "normal",
                value: element.office,
              },
              {
                label: "Office Number",
                style: "normal",
                value: this.getOfficePhoneNumber(
                  element.office_contact_details
                ),
              },
            ],
          },
          {
            title: "Employee Information",
            style: "subtitle",
            border: true,
            borderStyle: "borderThin",
            collapsible: true,
            data: [
              {
                label: "Department",
                style: "normal",
                value: element.department,
              },
              {
                label: "Mobile Number",
                style: "normal",
                value: cleanPhoneNumber(element.mobile_number),
              },
              {
                label: "Email Address",
                style: "normal",
                value: element.email,
              },
              {
                label: "LinkedIn",
                style: "normal",
                value: element.linkedin,
              },
            ],
          },
        ]);
        this.advisorObjects.push(element);
      });

      return advisors;
    },
  },

  data() {
    return {
      advisorObjects: [],
      full_name: "",
      company: "",
      department: "",
      position: "",
      manager: "",
      email: "",
      mobile_number: "",
      office: "",
      office_number: "",
      office_fax_number: "",
      extension: "",
      linkedin: "",
      messageAdvisor: null,
      modalShowing: false,
    };
  },

  methods: {
    getOfficePhoneNumber(officeContactDetails) {
      if (officeContactDetails.includes("|")) {
        return cleanPhoneNumber(
          officeContactDetails.split("|")[0].split(":")[1]
        );
      }
      return cleanPhoneNumber(officeContactDetails);
    },

    getOfficeFaxNumber(officeContactDetails) {
      if (officeContactDetails.includes("|")) {
        return cleanPhoneNumber(
          officeContactDetails.split("|")[1].split(":")[1]
        );
      }
      return "-";
    },

    showModal(advisor) {
      const advisorName = advisor[0].title;
      this.messageAdvisor = _.find(
        this.advisorObjects,
        (adv) => `${adv.first_name} ${adv.last_name}` === advisorName
      );
      this.modalShowing = true;
    },

    hideModal() {
      this.modalShowing = false;
    },

    buildImageURL(employee) {
      const employeeName = `${employee.first_name.toLowerCase()} ${employee.last_name.toLowerCase()}`;
      const imageName = employeeName.replaceAll(" ", "_");
      return `${process.env.VUE_APP_S3_STORAGE_URL}/employees/${imageName}.png`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/home";
@import "@/assets/styles/components/informationcard/informationcard";
</style>
