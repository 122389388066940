<template>
  <div class="component--container" id="componentContainer">
    <div
      v-if="holdings && holdings.length > 0"
      class="table--container"
      id="holdingPerformanceTableContainer"
    >
      <h3>Holding Performance Summary</h3>
      <table>
        <thead>
          <tr>
            <th class="hide-on-mobile center-aligned-column">New</th>
            <th class="hide-on-mobile left-aligned-column">Product Code</th>
            <th class="left-aligned-column">Description</th>
            <th class="hide-on-mobile right-aligned-column">Units Held</th>
            <th class="hide-on-mobile right-aligned-column">Units Price</th>
            <th class="hide-on-mobile right-aligned-column">Gain/Loss</th>
            <th class="right-aligned-column">Performance Percentage</th>
            <th class="right-aligned-column">Product Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="holding in holdings" :key="holding.id">
            <td>
              <div v-if="holding.opening_date === holding.closing_date">
                &#x2713;
              </div>
            </td>
            <td class="hide-on-mobile left-aligned-column">
              {{ holding.product_code }}
            </td>
            <td class="left-aligned-column">
              {{ holding.product_description }}
            </td>
            <td class="hide-on-mobile right-aligned-column">
              {{ parseFloat(holding.closing_product_units_held).toFixed(2) }}
            </td>
            <td class="hide-on-mobile right-aligned-column">
              {{
                cleanMoney(
                  parseFloat(
                    holding[
                      `closing_${$store.getters.currency.toLowerCase()}_unit_price`
                    ]
                  ),
                  $store.getters.currency
                )
              }}
            </td>
            <td class="hide-on-mobile right-aligned-column">
              {{
                cleanMoney(
                  parseFloat(
                    holding[
                      `${$store.getters.currency.toLowerCase()}_gain_loss`
                    ]
                  ),
                  $store.getters.currency
                )
              }}
            </td>
            <td class="right-aligned-column">
              {{ parseFloat(holding.performance * 100).toFixed(2) }}%
            </td>
            <td class="right-aligned-column">
              {{
                cleanMoney(
                  parseFloat(
                    holding[
                      `closing_${$store.getters.currency.toLowerCase()}_amount`
                    ]
                  ),
                  $store.getters.currency
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { cleanMoney } from "@/utils/string_helpers";

export default {
  props: ["load"],

  computed: {
    portfolioValue() {
      return _.sum(
        this.$store.getters.holdingPeriod.map((element) => {
          return parseFloat(
            element[
              `closing_${this.$store.getters.currency.toLowerCase()}_amount`
            ]
          );
        })
      );
    },

    holdings() {
      return this.$store.getters.holdingPeriodNoCash;
    },
  },

  methods: {
    cleanMoney,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
</style>
