import { getFeature } from "@/utils/split_io.js";

export default {
  GET_FEATURE_PREFERENCES({ commit }) {
    return new Promise((resolve, reject) => {
      getFeature((client) => {
        const treatment = client.getTreatment("preferencePage");

        switch (treatment) {
          case "on":
            commit("SET_FEATURE_PREFERENCES", true);
            resolve(true);
            break;
          case "off":
            commit("SET_FEATURE_PREFERENCES", false);
            reject(false);
            break;
          default:
            commit("SET_FEATURE_PREFERENCES", false);
            reject(false);
        }
      });
    });
  },
};
