<template>
  <WidgetLoader v-if="loading" />
  <div
    class="chart--container"
    id="productsPerformanceChartContainer"
    :style="{ width }"
    v-if="loading === false"
  >
    <highcharts :options="chartOptions" ref="chart"></highcharts>

    <div class="buttons">
      <button @click="hide" class="chart--button--big">Hide All</button>
      <button @click="show" class="chart--button--big">Show All</button>
    </div>
  </div>
</template>

<script>
// COMPONENTS
import WidgetLoader from "@/components/loaders/WidgetLoader";
// SCRIPTS
import _ from "lodash";
import { months } from "@/utils/date_helpers.js";

export default {
  name: "productsPerformanceChart",

  components: { WidgetLoader },

  props: {
    width: {
      type: String,
      default: window.innerWidth,
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    this.$store.dispatch("INVESTMENTS").then(() => {
      this.loading = false;
    });
  },

  computed: {
    series() {
      const isVisible = this.$store.getters.productsGainLossState;
      return Array.from(
        _(this.$store.getters.investments)
          .groupBy((element) => element.product_code)
          .map((value) => ({
            description: value[0].product_description,
            name: value[0].product_description,
            visible: isVisible,
            data: value.map((element) => {
              const percentage = parseFloat(element.performance) * 100;
              return [new Date(element.opening_date).getTime(), percentage];
            }),
          }))
      );
    },

    chartOptions() {
      return {
        chart: {
          backgroundColor: "#ededed",
          spacing: [20, 20, 20, 20],
          renderTo: "container",
          type: "line",
        },

        credits: {
          enabled: false,
        },

        title: {
          text: "Products Gain/Loss Percentage",
          style: {
            color: "#881534",
            fontWeight: "bold",
          },
          y: 0,
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          enabled: true,
          useHTML: true,
          labelFormatter: function () {
            return this.name;
          },
          width: "40%",
          itemStyle: {
            textOverflow: "clip",
            overflowWrap: "break-word",
            wordWrap: "break-word",
          },
          itemMarginTop: 5,
          itemMarginBottom: 5,
        },

        tooltip: {
          useHTML: true,
          formatter() {
            const name = this.series.name;
            const value = parseFloat(this.y).toFixed(0);
            const dateObj = new Date(this.x);
            const date = `${dateObj.getDate()} ${
              months[dateObj.getMonth()]
            } ${dateObj.getFullYear()}`;
            return `<table>
                      <tr>
                        <th>
                          ${date}
                        </th>
                      </tr>
                      <tr>
                        <td>
                          ${name}:  <b>${value}%</b>
                        </td>
                      </tr>
                    </table>`;
          },
        },

        xAxis: {
          type: "datetime",
          title: {
            text: "Date Range",
          },
        },

        yAxis: {
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
          title: {
            text: "Percentage Change",
          },
        },

        series: this.series,
      };
    },
  },

  methods: {
    hide() {
      this.$refs.chart.chart.series.forEach((element) => {
        element.hide();
      });
    },

    show() {
      this.$refs.chart.chart.series.forEach((element) => {
        element.show();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
