import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/00_Login.vue";
import EmployeeLogin from "@/views/00_EmployeeLogin.vue";
import Page404 from "@/views/00_Page404.vue";
import HomeEmployee from "@/views/01_HomeEmployee.vue";
import Account from "@/views/01_Account.vue";
import HomeUser from "@/views/01_HomeUser.vue";
import Portfolio from "@/views/01_Portfolio.vue";
import Products from "@/views/01_Products.vue";
import Advisor from "@/views/01_Advisor.vue";
import Documents from "@/views/01_Documents.vue";
import Holdings from "@/views/01_Holdings.vue";
import Providers from "@/views/01_Providers.vue";
import UserPreferences from "@/views/01_UserPreferences.vue";
import store from "@/store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    store.dispatch("AUTH_LOGOUT");
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
  }
  next("/login");
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/login/employee/",
    name: "Employee Login",
    component: EmployeeLogin,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/",
    name: "Home",
    component: HomeUser,
    beforeEnter: ifAuthenticated,
    icon: "home",
  },
  {
    path: "/user/preferences",
    name: "Preferences",
    component: UserPreferences,
    beforeEnter: ifAuthenticated,
    icon: "cog",
    feature: "GET_FEATURE_PREFERENCES", // This is the store action dispatch command
  },
  {
    path: "/holdings",
    name: "Holdings",
    component: Holdings,
    beforeEnter: ifAuthenticated,
    icon: "hand-holding-usd",
  },
  {
    path: "/profile/account",
    name: "Account",
    component: Account,
    beforeEnter: ifAuthenticated,
    icon: "book",
  },
  {
    path: "/profile-search",
    name: "Profile Search",
    component: HomeEmployee,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/profile/portfolio",
    name: "Portfolio",
    component: Portfolio,
    beforeEnter: ifAuthenticated,
    icon: "chart-line",
  },
  {
    path: "/profile/products",
    name: "Product Performance",
    component: Products,
    beforeEnter: ifAuthenticated,
    icon: "chart-bar",
  },
  {
    path: "/advisor/profile",
    name: "Advisor",
    component: Advisor,
    beforeEnter: ifAuthenticated,
    icon: "users",
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    beforeEnter: ifAuthenticated,
    icon: "folder-open",
  },
  {
    path: "/providers",
    name: "Providers",
    component: Providers,
    beforeEnter: ifAuthenticated,
    icon: "landmark",
  },
  { path: "/:pathMatch(.*)*", component: Page404 },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
