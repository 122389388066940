<template>
  <DesktopNavigation
    :activeEntity="user_full_name"
    :active="active"
    :links="links"
    :onLinkClicked="onLinkClicked"
    :onLogoutClicked="onLogoutClicked"
    :toggleOpen="toggleNavigation"
    :isOpen="$store.getters.navigationState"
  />
  <MobileNavigation
    :activeEntity="user_full_name"
    :active="active"
    :links="links"
    :onLinkClicked="onLinkClicked"
    :onLogoutClicked="onLogoutClicked"
    :toggleOpen="toggleNavigation"
    :isOpen="$store.getters.navigationState"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { navigationLinkClicked } from "@/utils/analytics.js";
import DesktopNavigation from "./_DesktopNavigation";
import MobileNavigation from "./_MobileNavigation";

export default {
  components: { DesktopNavigation, MobileNavigation },
  data() {
    return {
      isDesktop: true,
    };
  },

  mounted() {
    if (window.innerWidth <= 600) {
      this.$store.dispatch("SET_NAVIGATION_STATE", { isOpen: false });
      this.isDesktop = false;
    }
  },

  methods: {
    toggleNavigation(isOpen) {
      this.$store.dispatch("SET_NAVIGATION_STATE", { isOpen });
    },

    onLinkClicked(linkObject) {
      if (!this.isDesktop) {
        this.$store.dispatch("SET_NAVIGATION_STATE", { isOpen: false });
      }

      const userProfile = this.$store.getters.user_profile;
      const userPk = this.$store.getters.user_pk;

      if (userProfile) {
        const birthDate = userProfile.birth_date;
        const position = userProfile.position;
        const employer = userProfile.employer;

        if (birthDate && position && employer) {
          navigationLinkClicked(userPk, linkObject, {
            birthDate,
            position,
            employer,
          });
        }
      }
      this.$router.push({ path: linkObject.url });
    },

    onLogoutClicked() {
      const employeeSession = this.$store.getters.employeeSession;
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        if (employeeSession) this.$router.push("/login/employee");
        else {
          this.$router.push("/login");
        }
      });
    },
  },

  computed: {
    ...mapGetters(["user_full_name"]),

    active() {
      const _active = this.$router.currentRoute.value.name;
      if (_active) return _active;
      return "";
    },

    links() {
      const links = [];
      const employeeSession = this.$store.getters.employeeSession;
      if (employeeSession) {
        links.push({
          name: "search",
          niceName: "Profile Search",
          url: "/profile-search",
          icon: "search",
        });
      }

      this.$router.options.routes.forEach((element) => {
        const exclude = [
          "Login",
          undefined,
          "Employee Login",
          "Profile Search",
        ];

        if (element.feature) {
          this.$store
            .dispatch("GET_FEATURE_PREFERENCES")
            .then((response) => {
              if (response === true)
                if (!exclude.includes(element.name)) {
                  links.push({
                    url: element.path,
                    name: element.name,
                    niceName: element.name,
                    icon: element.icon,
                  });
                }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (!this.$store.getters.hasPortfolio) {
            exclude.push("Holdings");
            exclude.push("Account");
            exclude.push("Portfolio");
            exclude.push("Product Performance");
          }

          if (!exclude.includes(element.name)) {
            links.push({
              url: element.path,
              name: element.name,
              niceName: element.name,
              icon: element.icon,
            });
          }
        }
      });
      return links;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/components/navigation/navigation";
</style>
