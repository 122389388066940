import _ from "lodash";
export const allowedPositions = [
  "Compliance and Case Manager",
  "Client Relationship Manager",
  "Para-Planner",
  "Junior Case Manager",
  "Compliance Manager",
  "Offshore New Business Administration Manager",
  "Senior Compliance Administrator",
  "Case Manager",
  "Junior Private Wealth Manager",
  "Private Wealth Manager",
  "Senior Private Wealth Manager",
  "Private Wealth Manager - Trainee",
  "Private Wealth Manager: Client Servicing",
  "New Business Administration - Manager",
  "General Manager",
  "Director",
  "Director: Acquisition",
  "Director: Corporate and Client Solutions",
  "Director: Development and Brand",
  "Director: Finance",
  "Director: Operations",
  "Director: Wealth Management and Advisory",
  "Executive Director",
  "Managing Director",
  "Managing Director: Carrick Investment Services (Mauritius)",
  "Managing Director: Carrick Wealth Botswana",
  "Managing Director: Carrick Wealth Kenya",
  "Managing Director: Carrick Wealth Malawi",
  "Managing Director: Carrick Wealth Zimbabwe",
  "Managing Director: Family Office",
  "Managing Director: Wealth Management and Advisory",
  "Non-Executive Director",
];

export default {
  advisor: (state) => state.advisor,
  filteredAdvisors: (state) =>
    _.filter(state.advisor, (a) => _.includes(allowedPositions, a.position)),
};
