export default {
  AUTH_REQUEST(state) {
    state.status = "loading";
    state.counter += 1;
  },

  EMPLOYEE_AUTH_REQUEST(state) {
    state.status = "loading";
    state.counter += 1;
  },

  AUTH_SUCCESS(state, data) {
    state.status = "success";
    state.user_token = data.token;
    state.tokenExpiry = data.tokenExpiry;
    state.hasLoadedOnce = true;
    state.counter += 1;

    localStorage.setItem("user-token", data.token);
    localStorage.setItem("token-expiry", data.tokenExpiry);
  },

  EMPLOYEE_AUTH_SUCCESS(state) {
    state.employeeSession = true;
    localStorage.setItem("user-session", true);
  },

  AUTH_ERROR(state) {
    state.status = "error";
    state.hasLoadedOnce = true;
  },

  AUTH_LOGOUT(state) {
    state.user_token = "";
  },
};
