<template>
  <section id="providerPage">
    <PageTitle :text="'Carrick Providers'" />
    <div class="providers--container">
      <div v-for="provider in activeProviders" :key="provider.id">
        <div class="provider--container">
          <div class="provider-image--container">
            <img
              :class="[
                'provider--image',
                provider.active === true ? '' : '--not-active',
              ]"
              id="providerImage"
              :src="`${storeUrl}/${provider.imageUrl}`"
              alt=""
            />
            <p class="provider-coming--text" v-if="provider.active === false">
              Coming soon
            </p>
          </div>
          <p class="provider-name--text">{{ provider.name }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import PageTitle from "@/components/general/PageTitle";

export default {
  name: "Provider Page",

  components: { PageTitle },

  mounted() {
    this.$store.dispatch("SET_PROVIDERS");
  },

  computed: {
    storeUrl() {
      return `${process.env.VUE_APP_S3_STORAGE_URL}/providers`;
    },
    activeProviders() {
      return _.sortBy(this.$store.getters.providers, "name");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
@import "@/assets/styles/views/providers";
</style>
