<template>
  <div class="information-notification--container">
    <div class="information-notification--content">
      <font-awesome-icon
        class="information-notification--icon"
        :icon="'info-circle'"
      /><i>{{ text }}</i>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
</style>
