export default {
  USER(state, userPk) {
    state.user_pk = userPk;
    localStorage.setItem("user-pk", userPk);
  },

  SET_USER(state, userId) {
    state.user_pk = userId;
    localStorage.setItem("user-pk", userId);
  },

  SET_USER_SEARCH(state, username) {
    state.user_search = username;
    localStorage.setItem("user-search", username);
  },

  USER_PROFILE(state, resp) {
    state.user_profile = resp;
  },

  SET_CURRENCY(state, resp) {
    state.currency = resp;
  },

  SET_POLICY(state, resp) {
    state.policy = resp;
  },

  GET_POLICIES(state, resp) {
    state.policies = resp;
  },

  HAS_PORTFOLIO(state, resp) {
    state.hasPortfolio = resp;
  },
};
