<template>
  <div class="home-holdings-table--container" id="holdingDataPeriodContainer">
    <div class="table--container" id="portfolioHoldingsTableContainer">
      <table>
        <thead>
          <tr>
            <th class="left-aligned-column">Holdings Description</th>
            <th style="width: 30%">Allocation</th>
            <th class="right-aligned-column" style="width: 20%">
              Current Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="portfolio in this.$store.getters.sortedPortfolios"
            :key="portfolio.id"
          >
            <td class="left-aligned-column decription">
              {{ portfolio.product_description }}
            </td>

            <td class="center-aligned-column">
              <ProgessBar
                :percentageValue="
                  percentageOf(
                    parseFloat(
                      portfolio[
                        `${$store.getters.currency.toLowerCase()}_amount`
                      ]
                    ),
                    $store.getters.total,
                    2
                  )
                "
              />
            </td>

            <td class="right-aligned-column">
              {{
                cleanMoney(
                  parseFloat(
                    portfolio[`${$store.getters.currency.toLowerCase()}_amount`]
                  ),
                  $store.getters.currency
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// COMPONENTS
import ProgessBar from "@/components/general/ProgressBar.vue";
// SCRIPTS
import { cleanMoney } from "@/utils/string_helpers";
import { percentageOf } from "@/utils/math_helpers";

export default {
  components: { ProgessBar },

  methods: {
    cleanMoney,
    percentageOf,
  },
};
</script>
