<template lang="">
  <div class="toggle-switch--container">
    <div class="preference--card">
      <label :id="`${id}LabelText`" class="label--text">{{ label }}</label>

      <p :id="`${id}DescriptionText`" class="description">{{ description }}</p>

      <label class="switch">
        <input
          :id="`${id}Input`"
          type="checkbox"
          :checked="initialState"
          v-on:change="inputChanged"
        />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "label", "description", "initialState"],

  methods: {
    inputChanged(event) {
      this.$emit("toggle-changed", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
@import "@/assets/styles/components/toggleswitch/toggleswitch.scss";
</style>
