import _ from "lodash";

export default {
  investments: (state) => {
    let _investments = state.investments.filter(
      (element) =>
        element.policy_number === state.policy || state.policy === "all"
    );
    return _investments.sort(
      (a, b) => new Date(a.closing_date) - new Date(b.closing_date)
    );
  },

  portfolios: (state) => state.portfolios,

  sortedPortfolios: (state) => {
    return sortPortfolios(state.portfolios, state.policy);
  },

  portfoliosByProvider: (state) => {
    return _(sortPortfolios(state.portfolios, state.policy)).groupBy(
      "provider"
    );
  },

  portfolioHoldings: (state) => {
    return sortPortfolios(excludeCash(state.portfolios), state.policy);
  },
};

const excludeCash = (portfolios) => {
  return portfolios.filter((element) => element.product_category != "Cash");
};

const sortPortfolios = (portfolios, policy) => {
  let _portfolios = portfolios.filter(
    (element) => element.policy_number === policy || policy === "all"
  );
  return _portfolios.sort((a, b) => b.gbp_amount - a.gbp_amount);
};
