export default {
  portfolioValuation: (state) => {
    const sumPortfolioData = [];
    const currency = state.currency.toLowerCase();
    const policyNumber = state.policy;
    const dateMap = {};

    state.portfolioValuation.forEach((element) => {
      if (policyNumber === "all" || policyNumber == element.policy_number) {
        const date = element.date;
        if (!Object.keys(dateMap).includes(element.date)) {
          dateMap[date] = 0.0;
        }
        let currentValue = dateMap[date];
        dateMap[date] = element[`${currency}_total`] + currentValue;
      }
    });

    Object.keys(dateMap).forEach((element) => {
      const date = element;
      const value = dateMap[date];
      sumPortfolioData.push({ date, value });
    });
    return sumPortfolioData;
  },
};
