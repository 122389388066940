import { getPortfolioValuation, clearCurrentSession } from "@/utils/api";

export default {
  PORTFOLIO_VALUATION({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.portfolioValuation && state.portfolioValuation.length > 0) {
        resolve(state.portfolioValuation);
      } else {
        getPortfolioValuation()
          .then((resp) => {
            commit("PORTFOLIO_VALUATION", resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            if (err.response.status === 401) clearCurrentSession();
            reject(err);
          });
      }
    });
  },
};
