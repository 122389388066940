<template>
  <section id="holdingsPage">
    <PageLoader v-if="loading" />
    <div v-else>
      <PageTitle :text="'Holdings'" />

      <PortfolioProductBreakdownPie />

      <TitleText :text="'Portfolio Holdings'" />
      <PortfolioHoldings />

      <LastUpdated model="current_portfolio_holdings" />
    </div>
  </section>
</template>

<script>
import TitleText from "@/components/general/TitleText";
import PageTitle from "@/components/general/PageTitle";
import LastUpdated from "@/components/lastupdated/LastUpdated";
import PageLoader from "@/components/loaders/PageLoader";
import PortfolioHoldings from "@/components/holdings/PortfolioHoldings";
import PortfolioProductBreakdownPie from "@/components/holdings/PortfolioProductBreakdownPie.vue";

export default {
  name: "Home",

  components: {
    PageTitle,
    TitleText,
    LastUpdated,
    PageLoader,
    PortfolioHoldings,
    PortfolioProductBreakdownPie,
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    const portfoliosPromise = this.$store.dispatch("PORTFOLIOS", {});
    const lastUpdatedPromise = this.$store.dispatch("LAST_UPDATED", {});
    Promise.all([portfoliosPromise, lastUpdatedPromise]).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base";
</style>
