<template>
  <div class="table--container" id="portfolioHoldingsTableContainer">
    <table>
      <thead>
        <tr>
          <th class="hide-on-mobile left-aligned-column">
            Holdings Description
          </th>
          <th class="left-aligned-column">Policy Number</th>
          <th class="hide-on-mobile right-aligned-column">Unit Price</th>
          <th class="hide-on-mobile right-aligned-column">Units Held</th>
          <th class="right-aligned-column">Current Value</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="portfolio in $store.getters.sortedPortfolios"
          :key="portfolio.id"
        >
          <td class="left-aligned-column">
            {{ portfolio.product_description }}
          </td>
          <td class="hide-on-mobile left-aligned-column">
            {{ portfolio.policy_number }}
          </td>
          <td class="hide-on-mobile right-aligned-column">
            {{
              cleanMoney(
                parseFloat(portfolio.product_unit_price),
                portfolio.product_currency.toLowerCase()
              )
            }}
          </td>

          <td class="hide-on-mobile right-aligned-column">
            {{ parseFloat(portfolio.product_units_held).toFixed(2) }}
          </td>
          <td class="right-aligned-column">
            {{
              cleanMoney(
                parseFloat(
                  portfolio[`${$store.getters.currency.toLowerCase()}_amount`]
                ),
                $store.getters.currency
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { cleanMoney } from "@/utils/string_helpers";

export default {
  methods: {
    cleanMoney,
  },
};
</script>

<style lang="scss">
.holding-data-period-value {
  margin-bottom: 15px;
}
@import "@/assets/styles/base";
</style>
