export default {
  currencyRates: (state) => {
    const currencies = {};
    state.currencyRates.forEach((element) => {
      const currency = `${element.code}-${element.base_code}`;
      if (!Object.keys(currencies).includes(currency)) {
        currencies[currency] = [];
      }
      currencies[currency].push({ date: element.date, value: element.value });
    });
    return currencies;
  },
};
