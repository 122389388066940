import store from "@/store";

export const getUserData = () => {
  const userProfile = store.getters.user_profile;
  const userId = store.getters.user_pk;

  if (userProfile) {
    const birthDate = userProfile.birth_date;
    const position = userProfile.position;
    const employer = userProfile.employer;
    return { userId, birthDate, position, employer };
  }

  return { userId };
};

export const isCashProduct = (product) => {
  return (
    product.product_description.toLowerCase() === "cash" ||
    (product.policy_number.startsWith("PL") &&
      product.product_description.toLowerCase().includes("liquidity"))
  );
};